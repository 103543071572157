import React, { useEffect, useState } from "react";
import { initMenu } from "../../utils/menu";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { useStore } from "../../stores/StoreContext";
import { observer } from "mobx-react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import UserService from "../../services/UserService";
import StoreService from "../../services/StoreService";

export const Menu = observer((props) => {
  const [user, setUser] = useState();
  const [entityList, setEntityList] = useState([]);
  const [activeStoreId, setActiveStoreId] = useState();
  const [cartTotalItems, setCartTotalItems] = useState();

  let auth = useAuth();
  const navigate = useNavigate();
  const { cartStore } = useStore();
  const location = useLocation();

  useEffect(() => {
    initMenu();
    var currentUser = UserService.getCurrentUser();
    setUser(currentUser);
    if (currentUser.entityList && currentUser.entityList !== "null") {
      var entityListParsed = JSON.parse(currentUser.entityList);
      setEntityList(entityListParsed);
    }
    setActiveStoreId(StoreService.getStoreId());
  }, []);

  useEffect(() => {
    setCartTotalItems(cartStore.totalItems);
  }, [cartStore.totalItems]);

  const changeWorkingEntity = (storeId) => {
    setActiveStoreId(storeId);
    StoreService.setStoreId(storeId);
    cartStore.clearCart();
    window.location.reload();
  };

  const onLogout = () => {
    auth.logOut().then(() => {
      cartStore.clearCart();
      cartStore.clearBudget();

      navigate("/sign-in");
    });
  };

  return (
    <nav className="menu shadow" id="menu">
      <div className="content">
        <div className="overflow-hidden">
          <div className="menu-header d-none d-xl-block">
            <a className="d-block" href="/">
              <img className="logo" src="./assets/img/brand/logo-sm.png" alt="" />
            </a>
          </div>
          <ul className="menu-nav nav flex-xl-column">
            <li className="nav-item">
              <a className={`nav-link fw-bold text-capitalize ${location.pathname === "/" ? "active" : ""}`} href="#!" onClick={() => navigate("/")}>
                <span className="nav-icon fe fe-products"></span>
                <span className="nav-title">Products</span>
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link fw-bold text-capitalize ${location.pathname === "/orders" ? "active" : ""}`} href="#!" onClick={() => navigate("/orders")}>
                {/* {ordersStore.unseenOrdersCount > 0 && (
                  <span className="position-relative">
                    <span className="position-absolute top-0 start-100 translate-middle mt-n1">
                      <span className="badge text-uppercase text-white bg-danger rounded-pill" style={{ "--bs-bg-opacity": "1" }}>
                        {ordersStore.unseenOrdersCount}
                      </span>
                    </span>
                    <span className="nav-icon fe fe-shopping-bag"></span>
                  </span>
                )}

                {ordersStore.unseenOrdersCount === 0 && <span className="nav-icon fe fe-shopping-bag"></span>} */}
                <span className="nav-icon fe fe-orders"></span>
                <span className="nav-title">Orders</span>
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link fw-bold text-capitalize ${location.pathname === "/reports" ? "active" : ""}`} href="#!" onClick={() => navigate("/reports")}>
                <span className="nav-icon fe fe-trending-up-chart"></span>
                <span className="nav-title">Reports</span>
              </a>
            </li>
            <li className="nav-item d-xl-none">
              <a className={`nav-link fw-bold text-capitalize ${location.profile === "/profile" ? "active" : ""}`} href="#!" onClick={() => navigate("/profile")}>
                <span className="nav-icon fe fe-user"></span>
                <span className="nav-title">Profile</span>
              </a>
            </li>
            <li className="nav-item d-xl-none">
              <a className="nav-link fw-bold text-capitalize " href="#!" onClick={() => navigate("/cart")}>
                <span className="block position-relative">
                  <span className="nav-icon fe fe-cart"></span>
                  {cartTotalItems > 0 && (
                    <span className="position-absolute top-0 start-100 translate-middle mt-n1">
                      <span className="badge badge-sm fs-9 text-uppercase text-white bg-danger rounded-circle" style={{ "--bs-bg-opacity": "1" }}>
                        {cartTotalItems}
                      </span>
                    </span>
                  )}
                </span>
                <span className="nav-title">Cart</span>
              </a>
            </li>
          </ul>
        </div>

        <div className="menu-footer mt-auto d-none d-xl-block">
          <OverlayTrigger overlay={<Tooltip>Download NDSS W-9</Tooltip>} placement="right" rootClose={true}>
            <a className="nav-btn btn btn-light text-primary mb-3" href="#!" data-bs-toggle="tooltip" data-bs-custom-class="tooltip-white">
              <span className="fe fe-download"></span>
            </a>
          </OverlayTrigger>

          <div className="nav-avatar">
            <div className="dropdown-start">
              <a className="d-block lh-1" href="#!" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="box">
                  <img className="box-img rounded-circle" src={user && user.photoUrl} alt="" />
                </span>
              </a>

              <div className="dropdown-menu dropdown-menu-card">
                <div className="card-body p-0">
                  <div className="text-center my-2">
                    <a className="box mb-2" href="#!" onClick={() => navigate("/profile")}>
                      <img className="box-img rounded-circle" src={user && user.photoUrl} alt="" />
                    </a>
                    <p className="fs-7 mb-1 lh-1">
                      <a href="#!" onClick={() => navigate("/profile")}>
                        {user && user.username}
                      </a>
                    </p>
                    <p className="fs-8 mb-0 lh-1">
                      <a className="text-muted" href={`mailto:${user && user.email}`}>
                        {user && user.email}
                      </a>
                    </p>
                  </div>
                  {entityList && entityList.some((el) => el.Relationship !== "Self") && (
                    <>
                      <hr className="my-2" />
                      <div className="list-group list-group-flush m-2">
                        {entityList.map((item, i) => (
                          <div key={i} className={`list-group-item p-0 border-bottom-0 ${activeStoreId === item.StoreId ? "bg-light rounded-1" : ""}`}>
                            <a className="d-block p-2" href="#!" onClick={() => changeWorkingEntity(item.StoreId)}>
                              <span className="row g-2 align-items-center">
                                {item.PhotoUrl && (
                                  <span className="col-auto">
                                    <span className="box box-sm">
                                      <img className="box-img rounded-circle" src={item.PhotoUrl} alt="" />
                                    </span>
                                  </span>
                                )}
                                <span className={`col ml-n2 ${item.PhotoUrl ? "" : "py-1"}`}>
                                  <span className="d-block fs-7 lh-1">{item.EntityName}</span>
                                  {/* <span className="fs-8 text-muted">Subtitle</span> */}
                                </span>
                              </span>
                            </a>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  <hr className="my-2" />
                  <div className="d-flex align-items-center justify-content-center m-2">
                    <button className="btn btn-sm btn-white px-2" onClick={onLogout}>
                      <i className="fe fe-log-out"></i>
                      &nbsp;
                      <span>Logout</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="nav-avatar">
              <div className="dropdown-center">
                <a className="d-block lh-1" href="#!" data-bs-toggle="dropdown" aria-expanded="false">
                  <span className="box">
                    <img className="box-img rounded-circle" src={user && user.photoUrl} alt="" />
                  </span>
                </a>
                <div className="dropdown-menu">
                  <a href="#!" className="dropdown-item" onClick={() => navigate("/profile")}>Profile</a>
                  <a href="#!" className="dropdown-item" onClick={onLogout}>Logout</a>
                </div>
              </div>
            </div> */}
        </div>
      </div>
    </nav>
  );
});
