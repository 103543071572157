import { React, useRef } from "react";
import { useStore } from "../../stores/StoreContext";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import debounce from "debounce";

export const Search = observer(() => {
  const searchInputRef = useRef(null);
  const { searchStore } = useStore();
  const onChange = async (e) => {
    searchStore.setSearchTerm(e.target.value !== "" ? e.target.value.split(" ") : []);
  };
  const location = useLocation();

  let placeholder = "Search";
  if (location) {
    if (["/"].includes(location.pathname)) placeholder = "Search by product sku, name, attributes...";
    else if (["/orders", "/documents"].includes(location.pathname)) placeholder = "Search by order number, date...";
  }

  const clearSearch = () => {
    searchStore.setSearchTerm([]);
    searchInputRef.current.value = "";
  };

  const setSelectedProduct = (item) => {
    searchStore.setSelectedProductId(item.id);
  };

  const hideSearchHistory = () => {
    var search = document.getElementById("search");
    var searchHistory = document.getElementById("search");
    if (searchHistory.classList.contains("show")) {
      search.click();
    }
  };

  return (
    <form
      className="search-form form-inline"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="input-group input-group-merge input-group-reverse h-100">
        {searchStore.searchTerm && searchStore.searchTerm.length > 0 && (
          <div className="input-group-feedback position-absolute opacity-1" onClick={clearSearch}>
            <i className="fe fe-x"></i>
          </div>
        )}
        <input
          id="search"
          ref={searchInputRef}
          minLength={1}
          className="form-control bg-light search-clear"
          type="search"
          data-bs-toggle="dropdown"
          placeholder={placeholder}
          aria-label="Search"
          autoComplete="off"
          onChange={debounce(async (e) => onChange(e), 600)}
          onKeyUp={hideSearchHistory}
        />
        <div className="input-group-text bg-light">
          <i className="fe fe-search"></i>
        </div>
        <div
          id="search-history"
          className="dropdown-menu dropdown-menu-card"
          style={{
            display: searchStore.searchHistoryProducts.length === 0 || ["/orders", "/documents"].includes(location.pathname) ? "none" : "",
          }}
          onMouseLeave={hideSearchHistory}
        >
          <div className="card-body p-2">
            <span className="p-1 text-muted small">Search history</span>
            <hr className="p-0 m-1"></hr>
            <div className="list-group list-group-flush">
              {searchStore.searchHistoryProducts.length > 0 &&
                searchStore.searchHistoryProducts.map((item, i) => {
                  return (
                    <a key={i} className="list-group-item" href="#!" onClick={() => setSelectedProduct(item)}>
                      <span className="row g-0 align-items-center">
                        <span className="col-auto">
                          <span className="box">
                            <img className="box-img rounded" src={item.mainImageUrl} alt="" style={{ objectFit: "contain" }} />
                          </span>
                        </span>
                        <span className="col ms-3">
                          <span className="fs-7">
                            {item.manufacturer} {item.primaryAttributes[0].value} &middot; {item.primaryAttributes[1].value} &middot; {item.primaryAttributes[2].value}
                          </span>
                        </span>
                      </span>
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});
