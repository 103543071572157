import Highlighter from "react-highlight-words";
import { observer } from "mobx-react";
import { formatPrice } from "../../../utils/formatters";
import debounce from "debounce";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export const ProductCardItem = observer((props) => {
  return (
    <div className="col">
      <div
        id={`card-${props.subcategory}-${props.index}`}
        data-index={props.index}
        data-type={props.subcategory}
        className={`card card-lift card-lift-expand mb-0 h-100 rounded-1 overflow-hidden shadow-none ${props.selectedItem && props.selectedItem.id === props.item.id ? "selected" : ""}`}>
        <div className="card-body p-0 lh-1">
          <div className="d-flex flex-column h-100">
            <a
              id={`show-details-btn-${props.subcategory}-${props.index}`}
              className="d-block h-100"
              href="#!"
              data-toggle="flyout"
              data-page="dashboard-product-preview-1.html"
              title={`${props.item.primaryAttributes.length > 0 && props.item.primaryAttributes[0].value} · ${props.item.primaryAttributes.length > 1 && props.item.primaryAttributes[1].value} · ${props.item.primaryAttributes.length > 2 && props.item.primaryAttributes[2].value
                }`}
              onClick={() => props.onSelectedItem(props.item.id, props.subcategory === "search")}
            >
              <span className="position-absolute top-0 start-0 end-0 p-2 row align-items-center">
                <span className="col fs-8 text-truncate text-uppercase text-muted">
                  <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={props.item.sku} />
                </span>
                <span className="col-auto">
                  <img src={props.item.manufacturerImageUrl} alt="" height="15" />
                </span>
              </span>
              <span className="d-block p-2 text-center">
                <img className="img-fluid my-4 rounded-1 w-75" src={props.item.package.imageUrl} alt="" />
                <span className="h6 mb-0 d-block text-black">
                  {props.item.primaryAttributes.length > 0 && props.item.primaryAttributes[0].description && (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="top"
                      overlay={<Tooltip>{props.item.primaryAttributes.length > 0 && props.item.primaryAttributes[0].description}</Tooltip>}
                      rootClose={true}
                    >
                      <span>
                        <Highlighter
                          searchWords={props.searchTerm ? props.searchTerm : [""]}
                          autoEscape={true}
                          textToHighlight={`${props.item.primaryAttributes.length > 0 && props.item.primaryAttributes[0].value}`}
                        />
                      </span>
                    </OverlayTrigger>
                  )}
                  {props.item.primaryAttributes.length > 0 && !props.item.primaryAttributes[0].description && (
                    <Highlighter
                      searchWords={props.searchTerm ? props.searchTerm : [""]}
                      autoEscape={true}
                      textToHighlight={`${props.item.primaryAttributes.length > 0 && props.item.primaryAttributes[0].value}`}
                    />
                  )}
                  &nbsp;&middot;&nbsp;
                  {props.item.primaryAttributes.length > 1 && props.item.primaryAttributes[1].description && (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="top"
                      overlay={<Tooltip>{props.item.primaryAttributes.length > 1 && props.item.primaryAttributes[1].description}</Tooltip>}
                      rootClose={true}
                    >
                      <span>
                        <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={`${props.item.primaryAttributes[1].value}`} />
                      </span>
                    </OverlayTrigger>
                  )}
                  {props.item.primaryAttributes.length > 1 && !props.item.primaryAttributes[1].description && (
                    <Highlighter
                      searchWords={props.searchTerm ? props.searchTerm : [""]}
                      autoEscape={true}
                      textToHighlight={`${props.item.primaryAttributes.length > 1 && props.item.primaryAttributes[1].value}`}
                    />
                  )}
                  &nbsp;&middot;&nbsp;
                  {props.item.primaryAttributes.length > 2 && props.item.primaryAttributes[2].description && (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="top"
                      overlay={<Tooltip>{props.item.primaryAttributes.length > 2 && props.item.primaryAttributes[2].description}</Tooltip>}
                      rootClose={true}
                    >
                      <span>
                        <Highlighter
                          searchWords={props.searchTerm ? props.searchTerm : [""]}
                          autoEscape={true}
                          textToHighlight={`${props.item.primaryAttributes.length > 2 && props.item.primaryAttributes[2].value}`}
                        />
                      </span>
                    </OverlayTrigger>
                  )}
                  {props.item.primaryAttributes.length > 2 && !props.item.primaryAttributes[2].description && (
                    <Highlighter
                      searchWords={props.searchTerm ? props.searchTerm : [""]}
                      autoEscape={true}
                      textToHighlight={`${props.item.primaryAttributes.length > 2 && props.item.primaryAttributes[2].value}`}
                    />
                  )}
                </span>
              </span>
            </a>
            <div className="p-2 row align-items-center border-top">
              <div className="col">
                <span className="d-block">
                  <span className="fs-6 text-black">{formatPrice(props.item.unitPrice)}</span>
                  <span className="fs-8 text-muted">/{props.item.measurementUnit}</span>
                </span>
                <span className="d-block">
                  <span className="fs-7 text-black">{props.item.package.unitQuantity}&nbsp;</span>
                  <span className="fs-8 text-muted">per {props.item.package.name.toLowerCase()}</span>
                </span>
              </div>
              <div className="col">
                {props.itemCartQuantity(props.item.id, props.item.package.id) === 0 && (
                  <button
                    className="btn btn-white btn-sm w-100 text-muted rounded-pill py-1"
                    data-bs-toggle="toast"
                    data-bs-target="#product-toast"
                    onClick={() => props.addToCart(props.item.id)}
                    disabled={props.isBudgetReached(props.item.price)}
                  >
                    <span className="fe fe-cart me-1"></span>
                    <span>Add</span>
                  </button>
                )}

                {props.itemCartQuantity(props.item.id, props.item.package.id) !== 0 && (
                  <div className="btn-number d-inline-flex btn-sm mw-100 w-100  rounded-pill">
                    <button className="btn btn-sm btn-link btn-minus px-1" onClick={() => props.removeFromCart(props.item.id, props.item.package.id)}>
                      <span className="fe fe-minus align-middle"></span>
                    </button>
                    <input
                      key={`home-${props.item.id}-${props.item.package.id}-${props.itemCartQuantity(props.item.id, props.item.package.id)}`}
                      className={`form-control form-control-sm form-control-flush  px-0 text-center rounded-0 id-${props.item.id}-${props.item.package.id}`}
                      type="number"
                      name="quantity"
                      defaultValue={props.itemCartQuantity(props.item.id, props.item.package.id)}
                      disabled={props.isBudgetReached(props.item.price)}
                      onFocus={(e) => e.target.select()}
                      onBlur={(e) => {
                        if (!e.target.value || !e.target.value.length || isNaN(parseInt(e.target.value)))
                          props.removeFromCart(props.item.id, props.item.package.id);
                      }}
                      onChange={debounce(async (e) => {
                        props.addToCart(props.item.id, parseInt(e.target.value), true);
                      }, 500)}
                      min={0}
                    />
                    <button className="btn btn-sm btn-link btn-plus px-1" onClick={() => props.addToCart(props.item.id)} disabled={props.isBudgetReached(props.item.price)}>
                      <span className="fe fe-plus align-middle"></span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
});
