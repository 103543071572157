import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import React from "react";
import FiltersService from "../../../services/FiltersService";
import { useStore } from "../../../stores/StoreContext";
import { observer } from "mobx-react";
import Select from "react-select";
const ProductFiltersModal = observer(({ onFilterChange, onManufacturersChange, onBrandLinesChange, onTypeChange, onSet, onClearAll }) => {
  const { filtersStore } = useStore();
  const [subcategoryManufacturers, setSubcategoryManufacturers] = useState([]);
  const [subcategoryBrandLines, setSubcategoryBrandLines] = useState([]);

  const onSubcategoryChange = async (subcategoryId) => {
    onTypeChange(subcategoryId);

    var manufacturers = await FiltersService.getManufacturersBySubcategoryId(subcategoryId);

    const currentSubcategoryManufacturersIndex = subcategoryManufacturers ? subcategoryManufacturers.findIndex((sf) => sf.subcategoryId === subcategoryId) : -1;
    var subcategoryFilters = filtersStore.filters.find((f) => f.id === subcategoryId);
    if (currentSubcategoryManufacturersIndex === -1) {
      if (subcategoryFilters) {
        manufacturers.forEach((manufacturer) => {
          manufacturer.isSelected = subcategoryFilters.manufacturerIdList && subcategoryFilters.manufacturerIdList.some((mId) => mId === manufacturer.id);
        });
      }
      setSubcategoryManufacturers((previousSubcategoryManufacturers) => [...previousSubcategoryManufacturers, { subcategoryId: subcategoryId, manufacturers: manufacturers }]);
    }

    const currentSubcategoryBrandLinesIndex = subcategoryBrandLines ? subcategoryBrandLines.findIndex((sf) => sf.subcategoryId === subcategoryId) : -1;

    if (currentSubcategoryBrandLinesIndex === -1 && subcategoryFilters && subcategoryFilters.manufacturerIdList && subcategoryFilters.manufacturerIdList.length > 0) {
      var brandLines = await FiltersService.getBrandLinesBySubcategoryId(subcategoryId, subcategoryFilters.manufacturerIdList);

      if (subcategoryFilters) {
        brandLines.forEach((brandLine) => {
          brandLine.isSelected = subcategoryFilters.brandLineIdList && subcategoryFilters.brandLineIdList.some((bId) => bId === brandLine.id);
        });
      }
      setSubcategoryBrandLines((previousSubcategoryBrandLines) => [...previousSubcategoryBrandLines, { subcategoryId: subcategoryId, brandLines: brandLines }]);
    }
  };

  const getFiltersBySubcategoryId = (subcategoryId) => {
    const currentSubcategoryIndex = filtersStore.filters ? filtersStore.filters.findIndex((s) => s.id === subcategoryId) : -1;
    if (currentSubcategoryIndex === -1) return [];

    return filtersStore.filters[currentSubcategoryIndex].attributes;
  };

  const getManufacturersBySubcategoryId = (subcategoryId) => {
    const currentSubcategoryManufacturersIndex = subcategoryManufacturers ? subcategoryManufacturers.findIndex((sm) => sm.subcategoryId === subcategoryId) : -1;
    return currentSubcategoryManufacturersIndex !== -1 ? subcategoryManufacturers[currentSubcategoryManufacturersIndex].manufacturers : null;
  };

  const loadBrandLines = async (subcategoryId, manufacturerIds) => {
    var brandLines = manufacturerIds.length > 0 ? await FiltersService.getBrandLinesBySubcategoryId(subcategoryId, manufacturerIds) : [];
    const currentSubcategoryBrandLinesIndex = subcategoryBrandLines ? subcategoryBrandLines.findIndex((sf) => sf.subcategoryId === subcategoryId) : -1;
    if (currentSubcategoryBrandLinesIndex === -1) {
      var subcategoryFilters = filtersStore.filters.find((f) => f.id === subcategoryId);
      if (subcategoryFilters) {
        brandLines.forEach((brandLine) => {
          brandLine.isSelected = subcategoryFilters.brandLineIdList && subcategoryFilters.brandLineIdList.some((bId) => bId === brandLine.id);
        });
      }
      setSubcategoryBrandLines((previousSubcategoryBrandLines) => [...previousSubcategoryBrandLines, { subcategoryId: subcategoryId, brandLines: brandLines }]);
    } else {
      subcategoryBrandLines[currentSubcategoryBrandLinesIndex].brandLines = brandLines;
      setSubcategoryBrandLines(subcategoryBrandLines);
    }
  };

  const getBrandLinesBySubcategoryId = (subcategoryId) => {
    const currentSubcategoryBrandLinesIndex = subcategoryBrandLines ? subcategoryBrandLines.findIndex((sbl) => sbl.subcategoryId === subcategoryId) : -1;
    return currentSubcategoryBrandLinesIndex !== -1 ? subcategoryBrandLines[currentSubcategoryBrandLinesIndex].brandLines : [];
  };

  useEffect(() => {
    var tabToggleButtons = document.querySelectorAll(".filter-tab");
    if (tabToggleButtons && tabToggleButtons.length > 0 && [...tabToggleButtons].every((ttb) => !ttb.classList.contains("active"))) {
      tabToggleButtons[0].click();
    }
  }, [filtersStore.filters.length]);

  return ReactDOM.createPortal(
    <div className="modal fade" id="set-product-filters" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
        <div className="modal-content border-0">
          <div className="modal-header pb-0 border-0">
            <h5 className="modal-title lh-1">Set Filters</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="card-body pt-0">
              <ul className="nav nav-pills nav-justified nav-overflow mb-3">
                {filtersStore.filters &&
                  filtersStore.filters.map((item, i) => (
                    <li className="nav-item" key={i}>
                      <button
                        className="nav-link d-flex align-items-center justify-content-center filter-tab"
                        id={`filter-${item.id}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#filter-${item.id}-tab-pane`}
                        role="tab"
                        aria-controls={`filter-${item.id}-tab-pane`}
                        aria-selected="true"
                        onClick={() => onSubcategoryChange(item.id)}
                      >
                        <img className="d-none d-md-inline-block img-white me-2" src={item.imageUrl} alt="" height="14" />
                        <span>{item.name}</span>
                      </button>
                    </li>
                  ))}
              </ul>
              <form>
                <div className="tab-content" id="filter-tab-content">
                  {filtersStore.filters &&
                    filtersStore.filters.map((item, i) => (
                      <div key={i} className="tab-pane show" id={`filter-${item.id}-tab-pane`} role="tabpanel" aria-labelledby={`filter-${item.id}-tab`} tabIndex={i}>
                        {getFiltersBySubcategoryId(item.id).map((filter, j) => (
                          <React.Fragment key={j}>
                            {filter.enumCode !== 4 && (
                              <div className="mb-3">
                                <label className="form-label">{filter.name}</label>
                                <Select
                                  key={`${filter.id}-${filter.attributeFilterValues.filter((afv) => afv.isSelected).length}`}
                                  defaultValue={filter.attributeFilterValues
                                    .filter((afv) => afv.isSelected)
                                    .map((valueItem, i) => ({
                                      value: valueItem.value,
                                      label: valueItem.value,
                                    }))}
                                  isMulti
                                  options={filter.attributeFilterValues.map((valueItem, i) => ({
                                    value: valueItem.value,
                                    label: valueItem.value,
                                  }))}
                                  onChange={(e) => onFilterChange(filter.id, filter.enumCode, e)}
                                  classNames={{ control: () => "form-control form-control-select" }}
                                />
                              </div>
                            )}
                            {filter.enumCode === 4 && (
                              <div className="mb-3">
                                <label className="form-label">{filter.name}</label>
                                <div className="row">
                                  {filter.attributeFilterValues.map((valueItem, k) => (
                                    <div className="col-6 col-sm-6" key={k}>
                                      <label className="form-check-label w-100" htmlFor={`${item.id}-${filter.id}-${valueItem.value}`}>
                                        <div className="card mb-0">
                                          <div className="card-body py-2">
                                            <div className="form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input rounded"
                                                id={`${item.id}-${filter.id}-${valueItem.value}`}
                                                autoComplete="off"
                                                value={valueItem.value}
                                                onChange={(e) => onFilterChange(filter.id, filter.enumCode, e)}
                                                defaultChecked={valueItem.isSelected}
                                              />
                                              {valueItem.value}
                                            </div>
                                          </div>
                                        </div>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}

                        <div className="mb-3">
                          <label className="form-label">Manufacturers</label>
                          {getManufacturersBySubcategoryId(item.id) && (
                            <Select
                              defaultValue={getManufacturersBySubcategoryId(item.id)
                                .filter((m) => m.isSelected)
                                .map((manufacturer, i) => ({
                                  value: manufacturer.id,
                                  label: manufacturer.name,
                                }))}
                              isMulti
                              name={`${item.id}-manufacturers`}
                              options={getManufacturersBySubcategoryId(item.id).map((manufacturer, i) => ({
                                value: manufacturer.id,
                                label: manufacturer.name,
                              }))}
                              classNames={{ control: () => "form-control form-control-select" }}
                              onChange={async (e) => {
                                const manufacturerIds = e.map((e) => e.value);
                                await loadBrandLines(item.id, manufacturerIds);
                                onManufacturersChange(e);
                              }}
                            />
                          )}
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Brand Lines</label>
                          {getBrandLinesBySubcategoryId(item.id) && (
                            <Select
                              key={`${item.id}-brand-lines-${getBrandLinesBySubcategoryId(item.id).filter((bl) => bl.isSelected).length}`}
                              defaultValue={getBrandLinesBySubcategoryId(item.id)
                                .filter((bl) => bl.isSelected)
                                .map((brandLine, i) => ({
                                  value: brandLine.id,
                                  label: brandLine.name,
                                }))}
                              isMulti
                              name={`${item.id}-brand-lines`}
                              options={getBrandLinesBySubcategoryId(item.id).map((brandLine, i) => ({
                                value: brandLine.id,
                                label: brandLine.name,
                              }))}
                              classNames={{ control: () => "form-control form-control-select" }}
                              onChange={onBrandLinesChange}
                            />
                          )}
                        </div>

                        <div className="hstack gap-3 mt-3">
                          <button className="btn btn-lg btn-light w-100" type="button" onClick={onClearAll}>
                            Clear all
                          </button>
                          <button className="btn btn-lg btn-primary w-100" type="BUTTON" onClick={onSet}>
                            Set
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("product-filters-modal")
  );
});

export { ProductFiltersModal };
