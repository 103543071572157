import Modal from "../pages/_Layout/Modal/Modal";
import ModalBody from "../pages/_Layout/Modal/components/ModalBody";
import ModalHeader from "../pages/_Layout/Modal/components/ModalHeader";
import print from "print-js";
import { toDownloadUrl, toFileNameFromUrl } from "../utils/url";

export default function QuoteOrderModal(props) {
  return (
    <Modal>
      <ModalHeader>
        <h5 className="modal-title lh-1">Order successfully quoted</h5>
        <button type="button" className="btn-close" onClick={props.close} aria-label="Close"></button>
      </ModalHeader>
      <ModalBody>
        <div className="card mb-0 border-0 shadow-none">
          <div className="card-body p-0">
            <div className="alert alert-success mb-3 text-center" role="alert">
              Order reference number is <strong>{props.quoteOrderNumber}</strong>.<br></br>
              <br></br>You can download, print or share the document below.
            </div>
            <form>
              <div className="mb-3">
                {/* <div className="col-12 col-md-6 mx-auto">
                    <a
                      className="effect-hover d-block position-relative rounded-3 overflow-hidden"
                      href="/docs/quote.pdf"
                      target="_BLANK"
                    >
                      <span className="frame d-block ratio ratio-a4 z-index-1">
                        <img src="/docs/quote.jpg" alt="" />
                      </span>
                      <span
                        className="icon position-absolute d-flex align-items-center fs-3 justify-content-around z-index-2 text-white bg-primary"
                        style={{ "--bs-bg-opacity": "0.6" }}
                      >
                        <span className="fe fe-search"></span>
                      </span>
                    </a>
                  </div> */}
                <div className="card card-sm mb-0">
                  <div className="card-body">
                    <div className="row row-cols-1 g-2 mb-0">
                      {props.quoteOrderDocuments.map((d, i) => (
                        <div className="col" key={i}>
                          <div className="row g-2 align-items-center">
                            <div className="col-auto">
                              <img className="me-1" src="./assets/img/docs/pdf.svg" alt="" height="40" />
                            </div>
                            <div className="col">
                              <h6 className="mb-0">{d.documentName}</h6>
                              {
                                <p className="mb-0 fs-7 text-muted">
                                  <a href={d.documentUrl} target="_blank" rel="noreferrer">
                                    Click to view
                                  </a>
                                </p>
                              }
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* TODO: This should not be hardcoded to first element */}
              <div className="btn-group w-100">
                <a
                  className="btn btn-white quote-order-modal-url w-50"
                  target="_blank"
                  href={toDownloadUrl(props.quoteOrderDocuments[0].documentUrl)}
                  download={toFileNameFromUrl(props.quoteOrderDocuments[0].documentUrl)}
                >
                  <span className="fe fe-download"></span>
                  <span> Download</span>
                </a>
                <button className="btn btn-white w-50" type="button" onClick={() => print({
                  printable: props.quoteOrderDocuments[0].documentUrl,
                  type: "pdf",
                })}>
                  <span className="fe fe-printer"></span>
                  <span> Print</span>
                </button>
                {/* <button className="btn btn-white btn-disabled opacity-50" type="button">
                  <span className="fe fe-share-2"></span>
                  <span> Share</span>
                </button> */}
              </div>
              <div className="row g-2 mt-3">
                <div className="col-12 col-md-4">
                  <button className="btn btn-lg btn-light col-12" type="button" onClick={props.close}>
                    Close
                  </button>
                </div>
                <div className="col-12 col-md-8">
                  <button
                    className="btn btn-lg btn-primary col-12"
                    type="button"
                    onClick={() => {
                      props.close();
                      props.onPurchaseOrder();
                    }}
                  >
                    Create Purchase Order &nbsp;
                    <span className="fe fe-arrow-right"></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
