import initNumberBtn from "./number-btn";

export function hideFlyoutCard() {
  const duration = 400;
  const flyout = document.getElementById("flyout");
  const close = document.getElementById("flyout-close");

  if (close) {
    flyout.classList.add("animated");
    flyout.classList.remove("show");

    window.setTimeout(() => {
      flyout.classList.remove("animated");
    }, duration);
  }
}

export default function showFlyoutCard() {
  const flyout = document.getElementById("flyout");
  const duration = 400;

  initNumberBtn();

  flyout.classList.add("animated");
  flyout.classList.toggle("show");

  window.setTimeout(() => {
    flyout.classList.remove("animated");
  }, duration);
}
