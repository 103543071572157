import axios from "axios";
import UserService from "./UserService";
import StoreService from "./StoreService";
import moment from "moment";

// export const getOrdersByParameters = (searchTerm, filter) => {
//   return new Promise((resolve, reject) => {
//     try {
//       var orders = [];
//       if (filter) {
//         orders = orders.filter(
//           (o) => filter === "all" || o.currentStatus.toLowerCase() === filter
//         );
//       }

//       if (searchTerm) {
//         searchTerm = searchTerm.toLowerCase();
//         orders = orders.filter(
//           (o) =>
//             o.currentStatus.toLowerCase() === searchTerm ||
//             o.orderNumber.toLowerCase() === searchTerm
//         );
//       }
//       resolve(orders);
//     } catch (error) {}
//   });
// };

export const getByParameters = ({ orderStatusId, paymentStatusId, orderTypeId, paymentTypeId, shippingStatusId, orderBy, page, orderedDateFrom, orderedDateTo, searchParam, pageSize }) => {
  const currentUser = UserService.getCurrentUser();
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/Orders/GetUserOrders", {
          params: {
            storeId: StoreService.getStoreId(),
            orderBy,
            UserId: currentUser.userId,
            orderStatusId,
            paymentStatusId,
            orderTypeId,
            paymentTypeId,
            shippingStatusId,
            page,
            pageSize,
            searchParam,
            orderedDateFrom: orderedDateFrom ? moment(orderedDateFrom).format("YYYY-MM-DD") : null,
            orderedDateTo: orderedDateTo ? moment(orderedDateTo).format("YYYY-MM-DD") : null,
            MajorMilestone: true,
          },
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const getById = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/Orders/OrderDetails", {
          params: { orderId: id, storeId: StoreService.getStoreId() },
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const quoteOrder = (products) => {
  if (!products) {
    throw new Error("Cart cannot be empty if you want to submit your order.");
  }

  const currentUser = UserService.getCurrentUser();
  return new Promise((resolve, reject) => {
    try {
      axios
        .post("https://testapi-commerce.emaq.ba/api/Orders/Quote", {
          OrderItemsInsertRequest: products.map((p) => ({
            ItemId: p.product.id,
            Quantity: p.quantity,
            UnitPrice: p.package.price,
            PackageId: p.package.id,
          })),
          storeId: StoreService.getStoreId(),
          userId: currentUser.userId,
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const purchaseOrder = (orderId, products, paymentTypeId, creditCardInfo, bankCheckInfo) => {
  if (!products && !orderId) {
    throw new Error("Cart cannot be empty if you want to submit your order.");
  }

  if (!paymentTypeId && (!creditCardInfo || !bankCheckInfo)) {
    throw new Error("Purchase order action cannot be performed because payment method is not provided.");
  }

  const currentUser = UserService.getCurrentUser();
  return new Promise((resolve, reject) => {
    try {
      axios
        .post("https://testapi-commerce.emaq.ba/api/Orders/Purchase", {
          OrderItemsInsertRequest: products.map((p) => ({
            ItemId: p.product.id,
            Quantity: p.quantity,
            UnitPrice: p.package.price,
            PackageId: p.package.id,
          })),
          paymentRequest: {
            creditCardInfo,
            bankCheckInfo,
          },
          paymentTypeId,
          storeId: StoreService.getStoreId(),
          userId: currentUser.userId,
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const purchaseQuote = (orderId, billingShippingInfo, customDocument, paymentTypeId, creditCardInfo, bankCheckInfo) => {
  if (!orderId) {
    throw new Error("Purchase quote action cannot be performed because order identifier is not provided.");
  }

  if (!billingShippingInfo) {
    throw new Error("Purchase quote action cannot be performed because billing and shipping info is not provided.");
  }

  const currentUser = UserService.getCurrentUser();
  return new Promise((resolve, reject) => {
    try {
      axios
        .post("https://testapi-commerce.emaq.ba/api/Orders/PurchaseQuote", {
          paymentRequest:
              creditCardInfo || bankCheckInfo
              ? {
                  creditCardInfo,
                  bankCheckInfo,
                }
              : undefined,
          paymentTypeId,
          guestInfosUpdateRequest: billingShippingInfo,
          documentUploadRequest: customDocument,
          id: orderId,
          userId: currentUser.userId,
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const signDocuments = (orderId) => {
  if (!orderId) {
    throw new Error("For documents to be signed, you must specify the order identifier.");
  }

  return new Promise((resolve, reject) => {
    try {
      axios
        .post("https://testapi-commerce.emaq.ba/api/Orders/SignDocuments", {
          id: orderId,
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const buyNowOrder = (orderId, products, paymentTypeId, creditCardInfo, bankCheckInfo, billingShippingInfo) => {
  if (!products && !orderId) {
    throw new Error("Cart cannot be empty if you want to submit your order.");
  }

  if (!paymentTypeId && (!creditCardInfo || !bankCheckInfo)) {
    throw new Error("Buy order action cannot be performed because payment method is not provided.");
  }

  if (!billingShippingInfo) {
    throw new Error("Buy order action cannot be performed because billing and shipping info is not provided.");
  }

  const currentUser = UserService.getCurrentUser();
  return new Promise((resolve, reject) => {
    try {
      axios
        .post("https://testapi-commerce.emaq.ba/api/Orders/BuyNow", {
          orderId,
          guestInfosInsertRequest: billingShippingInfo,
          OrderItemsInsertRequest: products
            ? products.map((p) => ({
                ItemId: p.product.id,
                Quantity: p.quantity,
                UnitPrice: p.package.price,
                PackageId: p.package.id,
              }))
            : [],
          paymentRequest: {
            creditCardInfo,
            bankCheckInfo,
          },
          paymentTypeId,
          storeId: StoreService.getStoreId(),
          userId: currentUser.userId,
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const getDeliveryAndCutOffTime = (products) => {
  const currentUser = UserService.getCurrentUser();
  return new Promise((resolve, reject) => {
    try {
      axios
        .post("https://testapi-commerce.emaq.ba/api/Orders/DeliveryAndCutOffTime", {
          userId: currentUser.userId,
          storeId: StoreService.getStoreId(),
          orderItems: products.map((p) => ({
            ItemId: p.product.id,
            Quantity: p.quantity,
            UnitPrice: p.package.price,
            PackageId: p.package.id,
          })),
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const getLatestBillAndShipOrderDetails = () => {
  const currentUser = UserService.getCurrentUser();
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/Orders/LatestBillAndShipOrderDetails", {
          params: { userId: currentUser.userId, storeId: StoreService.getStoreId() },
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const getOrderStatuses = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/OrderStatusTranslations", {
          headers: { ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF", "Accept-Language": "en" },
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const getPaymentStatuses = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/PaymentStatusTranslations", {
          headers: { ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF", "Accept-Language": "en" },
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const getOrderTypes = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/OrderTypeTranslations", {
          headers: { ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF", "Accept-Language": "en" },
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const getPaymentTypes = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/PaymentTypeTranslations", {
          headers: { ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF", "Accept-Language": "en" },
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

export const getShippingStatuses = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/ShippingStatusTranslations", {
          headers: { ClientId: "1CAA63AA-45ED-48AE-8572-F7E9BDDAA3EF", "Accept-Language": "en" },
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch((err) => {
          resolve(err.response?.data);
        });
    } catch (error) {}
  });
};

const OrdersService = {
  getByParameters,
  getById,
  quoteOrder,
  purchaseOrder,
  purchaseQuote,
  buyNowOrder,
  signDocuments,
  getLatestBillAndShipOrderDetails,
  getOrderStatuses,
  getPaymentStatuses,
  getOrderTypes,
  getPaymentTypes,
  getShippingStatuses,
};

export default OrdersService;
