import React from "react";
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GOOGLE_TRACKING_ID, HOTJAR_ID, HOTJAR_SNIPPET_VERSION } from "./config/CONSTANTS";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(<App />);

ReactGA.initialize(GOOGLE_TRACKING_ID);
hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
