export const setStoreId = (storeId) => {
  localStorage.setItem("storeId", storeId);
};

export const getStoreId = () => {
  const storeId = localStorage.getItem("storeId");
  if (storeId) {
    return +storeId;
  }
  return 50;
};

const StoreService = {
  getStoreId,
  setStoreId,
};

export default StoreService;
