import { makeAutoObservable } from "mobx";

export class FiltersStore {
  filters = [];
  orderFilters = {};
  changed = 0;

  constructor() {
    this.setInitialValues();
    makeAutoObservable(this);
  }

  setInitialFilters(filters) {
    var oldFilters = this.filters;
    var initialFilters = filters;

    initialFilters.forEach((subcategory) => {
      var oldSubcategory = oldFilters.find((f) => f.id === subcategory.id);
      if (oldSubcategory) {
        subcategory.attributes.forEach((attribute) => {
          var oldAttribute = oldSubcategory.attributes.find((a) => a.id === attribute.id);
          if (oldAttribute) {
            attribute.attributeFilterValues.forEach((afv) => (afv.isSelected = oldAttribute.attributeFilterValues.some((af) => af.value === afv.value && af.isSelected)));
            attribute.anyValueSelected = attribute.attributeFilterValues.some((afv) => afv.isSelected);
          }
        });
        subcategory.manufacturerIdList = oldSubcategory.manufacturerIdList;
        subcategory.brandLineIdList = oldSubcategory.brandLineIdList;
        subcategory.anyFilterSelected =
          subcategory.attributes.some((a) => a.anyValueSelected) ||
          (subcategory.manufacturerIdList && subcategory.manufacturerIdList.length > 0) ||
          (subcategory.brandLineIdList && subcategory.brandLineIdList.length > 0);
      }
    });

    this.filters = initialFilters;
    localStorage.setItem("filters", JSON.stringify(initialFilters));
  }

  setFilters(filters) {
    filters.forEach((subcategory) => {
      subcategory.attributes.forEach((attribute) => {
        attribute.anyValueSelected = attribute.attributeFilterValues.some((afv) => afv.isSelected);
      });
      subcategory.anyFilterSelected =
        subcategory.attributes.some((a) => a.anyValueSelected) ||
        (subcategory.manufacturerIdList && subcategory.manufacturerIdList.length > 0) ||
        (subcategory.brandLineIdList && subcategory.brandLineIdList.length > 0);
    });
    this.filters = filters;
    localStorage.setItem("filters", JSON.stringify(filters));
  }

  setOrderFilters({ orderStatusId, paymentStatusId, orderTypeId, paymentTypeId, shippingStatusId, dateFrom, dateTo }) {
    this.orderFilters = {
      orderStatusId: orderStatusId,
      paymentStatusId: paymentStatusId,
      orderTypeId: orderTypeId,
      paymentTypeId: paymentTypeId,
      shippingStatusId: shippingStatusId,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    localStorage.setItem("orderFilters", JSON.stringify(this.orderFilters));
  }

  getFiltersParams(subcategoryId) {
    if (subcategoryId === "all") {
      var attributeFilters = {};
      var brandLineIdList = [];
      var manufacturerIdList = [];
      this.filters.forEach((f) => {
        f.attributes.forEach((attribute) => {
          const selectedValues = attribute.attributeFilterValues.filter((afv) => afv.isSelected).map((afv) => afv.value);
          if (selectedValues.length > 0) {
            if (!attributeFilters[attribute.id]) {
              attributeFilters[attribute.id] = selectedValues;
            } else {
              attributeFilters[attribute.id].push(...selectedValues);
            }
          }
        });
        if (f.brandLineIdList && f.brandLineIdList.length > 0) {
          brandLineIdList = [...brandLineIdList, ...f.brandLineIdList];
        }
        if (f.manufacturerIdList && f.manufacturerIdList.length > 0) {
          manufacturerIdList = [...manufacturerIdList, ...f.manufacturerIdList];
        }
      });
      return {
        attributeFilters,
        manufacturerIdList: manufacturerIdList,
        brandLineIdList: brandLineIdList,
      };
    } else {
      const subcategoryFilters = this.filters.find((f) => f.id === subcategoryId);
      if (subcategoryFilters) {
        var attributeFilters = {};
        subcategoryFilters.attributes.forEach((attribute) => {
          const selectedValues = attribute.attributeFilterValues.filter((afv) => afv.isSelected).map((afv) => afv.value);
          if (selectedValues.length > 0) {
            attributeFilters[attribute.id] = selectedValues;
          }
        });
        return {
          attributeFilters,
          manufacturerIdList: subcategoryFilters.manufacturerIdList,
          brandLineIdList: subcategoryFilters.brandLineIdList,
        };
      }
    }
    return {
      attributeFilters: {},
      manufacturerIdList: [],
      brandLineIdList: [],
    };
  }

  clearFilters(subcategoryId) {
    const subcategoryFiltersIndex = this.filters.findIndex((f) => f.id === subcategoryId);
    if (subcategoryFiltersIndex !== -1) {
      this.filters[subcategoryFiltersIndex].attributes.forEach((attribute) => {
        attribute.attributeFilterValues.forEach((afv) => (afv.isSelected = false));
        attribute.anyValueSelected = false;
      });
      this.filters[subcategoryFiltersIndex].manufacturerIdList = [];
      this.filters[subcategoryFiltersIndex].brandLineIdList = [];
      this.filters[subcategoryFiltersIndex].anyFilterSelected = false;
    }

    localStorage.setItem("filters", JSON.stringify(this.filters));
  }

  clearOrderFilters() {
    this.orderFilters = {};
    localStorage.removeItem("orderFilters");
  }

  setInitialValues() {
    var filters = localStorage.getItem("filters");
    if (filters) {
      this.filters = JSON.parse(filters);
    }
    var orderFilters = localStorage.getItem("orderFilters");
    if (orderFilters) {
      this.orderFilters = JSON.parse(orderFilters);
    }
  }
}

export default new FiltersStore();
