import React from "react";
import { observer } from "mobx-react";
import print from "print-js";
import { hideFlyoutCard } from "../../../utils/flyout";
import { toDownloadUrl, toFileNameFromUrl } from "../../../utils/url";

export const DocumentDetailComponent = observer((props) => {
  const onClose = () => {
    hideFlyoutCard();
  };
  return (
    <>
      <div className="content">
        <div className="container-fluid py-lg-3">
          <button
            className="btn-close position-absolute top-0 end-0 m-2 fs-4 d-xl-none"
            id="flyout-close"
            aria-label="Close"
            onClick={onClose}
          ></button>
          <div className="row align-items-center text-center text-md-start mb-3">
            <div className="col mb-2 mb-md-0">
              <h4 className="mb-1 fw-normal">
                {props.document.documentNumber}
              </h4>
              <div className="d-inline-flex align-items-center">
                <span className="me-2 fs-6 text-muted">
                  {props.document.date}
                </span>
                <span
                  className="badge text-uppercase text-primary bg-primary rounded-pill"
                  style={{ "--bs-bg-opacity": "0.1" }}
                >
                  {props.document.type}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-auto">
              <div className="btn-group">
                <a
                  className="btn btn-white"
                  target="_blank"
                  href={toDownloadUrl(props.document.url)}
                  download={toFileNameFromUrl(props.document.url)}
                >
                  <span className="fe fe-download"></span>
                  <span> Download</span>
                </a>
                <button className="btn btn-white">
                  <span
                    className="fe fe-printer"
                    onClick={() => print({
                      printable: props.document.url,
                      type: "pdf",
                    })}
                  ></span>
                  <span> Print</span>
                </button>
                {/* <button className="btn btn-white btn-disabled opacity-50" type="button">
                  <span className="fe fe-share-2"></span>
                  <span> Share</span>
                </button> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="ratio ratio-1x1 rounded-2 overflow-hidden">
                <iframe
                  key={props.document.documentNumber}
                  title={props.document.documentNumber}
                  src={props.document.url}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default DocumentDetailComponent;
