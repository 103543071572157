import React, { useEffect, useState } from "react";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../stores/StoreContext";
import { observer } from "mobx-react";
import CalibersService from "../../services/CalibersService";
import { Modal, closeActiveModal } from "../../components/Modal";
import UserService from "../../services/UserService";

const ProfileView = observer(() => {
  const [user, setUser] = useState();
  const [entityList, setEntityList] = useState([]);

  const [calibers, setCalibers] = useState([]);
  const [currentSubcategory, setCurrentSubcategory] = useState();
  const [selectedBulletTypes, setSelectedBulletTypes] = useState([]);
  const [activeBulletTypes, setActiveBulletTypes] = useState([]);

  let auth = useAuth();
  const navigate = useNavigate();
  const { cartStore } = useStore();

  const onLogout = () => {
    auth.logOut().then(() => {
      cartStore.clearCart();
      cartStore.clearBudget();

      navigate("/sign-in");
    });
  };

  useEffect(() => {
    var userJson = localStorage.getItem("loggedUser");
    if (userJson) {
      var user = JSON.parse(userJson);
      setUser(user);
      if (user.entityList && user.entityList !== "null") {
        var entityListParsed = JSON.parse(user.entityList);
        setEntityList(
          entityListParsed.filter(function (el) {
            return el.Relationship !== "Self";
          })
        );
      }
    }

    const fetchAndSetCaliberTypesAndUserInterests = async () => {
      const data = await CalibersService.getAllCalibers();
      setCalibers(data);

      var userInterests = await UserService.getCurrentUserInterests();
      setSelectedBulletTypes(userInterests);
      setActiveBulletTypes(userInterests);
    };
    fetchAndSetCaliberTypesAndUserInterests();

    var cart = document.getElementsByClassName("cart")[0];
    cart.classList.add("cart-full");
    document.body.classList.remove("sticky");
    return () => {
      cart.classList.remove("cart-full");
      document.body.classList.add("sticky");
    };
  }, []);

  useEffect(() => {}, [selectedBulletTypes, activeBulletTypes, currentSubcategory, calibers]);

  const onClearAll = async () => {
    const currentActiveBulletTypes = [...activeBulletTypes];
    var filtered = currentActiveBulletTypes.filter((csbt) => csbt.subcategoryId !== currentSubcategory.id);
    setActiveBulletTypes(filtered);
  };

  const onSet = async () => {
    const bulletTypesToDelete = selectedBulletTypes && selectedBulletTypes.filter((abt) => activeBulletTypes.findIndex((sbt) => sbt.value === abt.value) === -1);
    if (bulletTypesToDelete && bulletTypesToDelete.length > 0) {
      const ids = bulletTypesToDelete.map((bttd) => bttd.id).join();
      await UserService.removeUserInterests(ids);
    }
    const bulletTypesToAdd = activeBulletTypes && activeBulletTypes.filter((abt) => selectedBulletTypes.findIndex((sbt) => sbt.value === abt.value) === -1);
    if (bulletTypesToAdd && bulletTypesToAdd.length > 0) {
      bulletTypesToAdd.forEach((btta) => (btta.userId = user.userId));
      await UserService.addUserInterests(bulletTypesToAdd);
    }
    setSelectedBulletTypes(activeBulletTypes);
    closeActiveModal();
  };

  const onSelectedBulletType = (item) => {
    var currentActiveBulletTypes = [...activeBulletTypes];
    var index = currentActiveBulletTypes.findIndex((csbt) => csbt.subcategoryId === currentSubcategory.id && csbt.value === item);
    if (index !== -1) {
      currentActiveBulletTypes.splice(index, 1);
    } else {
      const newBulletType = { categoryId: 99, subcategoryId: currentSubcategory.id, attributeId: currentSubcategory.attributeId, value: item };
      currentActiveBulletTypes = [...currentActiveBulletTypes, newBulletType];
    }

    setActiveBulletTypes(currentActiveBulletTypes);
  };

  const getSelectedCalibers = (item) => {
    return selectedBulletTypes ? selectedBulletTypes.filter((sbt) => sbt.subcategoryId === item.id) : [];
  };

  const getActiveCalibersCount = (item) => {
    return activeBulletTypes ? activeBulletTypes.filter((sbt) => sbt.subcategoryId === item.id).length : 0;
  };

  const isBulletTypeChecked = (item) => {
    const indexActiveBulletTypes = currentSubcategory && activeBulletTypes && activeBulletTypes.findIndex((sbt) => sbt.subcategoryId === currentSubcategory.id && sbt.value === item);
    return indexActiveBulletTypes !== -1;
  };

  const openCalibersSelection = (subcategory) => {
    setCurrentSubcategory(subcategory);
  };

  return (
    <React.Fragment>
      <div className="container mt-xl-3">
        <div className="alert alert-info mb-3 text-white bg-info border-info rounded-3">
          <img className="me-2" src="https://s3.amazonaws.com/idme-design/brand-assets/Primary-IDme-Logo-RGB-white.png" height="20" /> Changing your personal information is only available through
          ID.me service.
        </div>

        <div className="mb-3">
          <h1 className="mb-3 fs-4">Your Profile</h1>
          <div className="row">
            <div className="col-12">
              <div className="card card-fill">
                <div className="card-body">
                  <div className="row g-0 align-items-center lh-1">
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div className="box box-lg">
                          <img className="box-img rounded-circle" src={user && user.photoUrl} alt="" />
                        </div>
                        <div className="ms-1 ms-md-3">
                          <h5 className="mb-1"> {user && <>{user.username}</>}</h5>
                          <p className="mb-1 fs-7 text-muted">
                            {user &&
                              entityList &&
                              entityList.map((entity, i) => {
                                return (
                                  <span>
                                    {" "}
                                    {entity.EntityName} {i + 1 !== entityList.length ? "|" : ""}{" "}
                                  </span>
                                );
                              })}
                          </p>
                          <p className="mb-0 fs-7 text-muted"> {user && user.phone}</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-auto ms-auto">
                      <a className="box" href="#!" data-bs-toggle="modal" data-bs-target="#edit-user">
                        <div className="box-title bg-light text-dark fs-5 rounded-circle fe fe-edit"></div>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h1 className="mb-3 fs-4">Armory</h1>
          <div className="row row-cols-1 g-2 mb-3">
            {calibers &&
              calibers.map((item, i) => (
                <div className="col" key={i}>
                  <label className="form-check-label w-100" htmlFor={`caliber-status-${i + 1}`}>
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="form-check">
                          <div className="row g-0 align-items-center">
                            <div className="col-auto">
                              <input
                                className="form-check-input"
                                id={`caliber-status-${i + 1}`}
                                type="checkbox"
                                name="caliber-status"
                                data-bs-toggle="modal"
                                data-bs-target="#select-calibers"
                                onClick={() => openCalibersSelection(item)}
                                checked={getSelectedCalibers(item).length > 0}
                                readOnly={true}
                              />
                            </div>
                            <div className="col ms-2">
                              <span className="d-block h6 mb-0">{item.name}</span>
                              <span className="d-block my-1 fs-7 lh-1 text-muted">
                                Selected {getSelectedCalibers(item).length} out of {item.values.length} bullet types.
                              </span>
                              {getSelectedCalibers(item).map((caliber, i) => (
                                <span key={i} className="badge text-uppercase text-secondary bg-secondary rounded-pill" style={{ "--bs-bg-opacity": "0.1" }}>
                                  {caliber.value}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              ))}

            {/* <div className="col">
              <label className="form-check-label w-100" htmlFor="caliber-status-1">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="form-check">
                      <div className="row g-0 align-items-center">
                        <div className="col-auto">
                          <input className="form-check-input" id="caliber-status-1" type="checkbox" name="caliber-status" data-bs-toggle="modal" data-bs-target="#select-calibers" checked="" />
                        </div>
                        <div className="col ms-2">
                          <span className="d-block h6 mb-0">Handgun</span>
                          <span className="d-block my-1 fs-7 lh-1 text-muted">Selected 1 out of 48 bullet types.</span>
                          <span className="badge text-uppercase text-primary bg-primary rounded-pill" style={{ "--bs-bg-opacity": "0.1" }}>
                            9MM LUGER
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
            <div className="col">
              <label className="form-check-label w-100" htmlFor="caliber-status-2">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="form-check">
                      <div className="row g-0 align-items-center">
                        <div className="col-auto">
                          <input className="form-check-input" id="caliber-status-2" type="checkbox" name="caliber-status" data-bs-toggle="modal" data-bs-target="#select-calibers" checked="" />
                        </div>
                        <div className="col ms-2">
                          <span className="d-block h6 mb-0">Rifle</span>
                          <span className="d-block my-1 fs-7 lh-1 text-muted">Selected 1 out of 147 bullet types.</span>
                          <span className="badge text-uppercase text-primary bg-primary rounded-pill" style={{ "--bs-bg-opacity": "0.1" }}>
                            40 S&amp;W
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
            <div className="col">
              <label className="form-check-label w-100" htmlFor="caliber-status-3">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="form-check">
                      <div className="row g-0 align-items-center">
                        <div className="col-auto">
                          <input className="form-check-input" id="caliber-status-3" type="checkbox" name="caliber-status" data-bs-toggle="modal" data-bs-target="#select-calibers" />
                        </div>
                        <div className="col ms-2">
                          <span className="d-block h6 mb-0">Shotgun</span>
                          <span className="d-block my-1 fs-7 lh-1 text-muted">Add caliber</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
            <div className="col">
              <label className="form-check-label w-100" htmlFor="caliber-status-4">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="form-check">
                      <div className="row g-0 align-items-center">
                        <div className="col-auto">
                          <input className="form-check-input" id="caliber-status-4" type="checkbox" name="caliber-status" data-bs-toggle="modal" data-bs-target="#select-calibers" />
                        </div>
                        <div className="col ms-2">
                          <span className="d-block h6 mb-0">Rimfire</span>
                          <span className="d-block my-1 fs-7 lh-1 text-muted">Add caliber</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div> */}
          </div>
          <h1 className="mb-3 fs-4">Actions</h1>
          <div className="row">
            {/* <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="mb-1">Delete your account</h5>
                      <p className="mb-0 fs-7 text-muted">Please note, deleting your account is a permanent action that cannot be reversed.</p>
                    </div>
                    <div className="col-auto">
                      <a className="btn btn-danger" href="#!" data-bs-toggle="modal" data-bs-target="#profile-delete">
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-12">
              <div className="card card-lift text-danger">
                <a className="card-body d-flex d-flex align-items-center" onClick={onLogout}>
                  <span className="fe fe-user"></span>
                  <span className="ms-2">Logout</span>
                  <span className="fe fe-log-out ms-auto"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal id="select-calibers" size="modal-lg">
        <div>
          <div className="card-header border-0">
            <h5 className="card-header-title">
              {currentSubcategory && currentSubcategory.name && currentSubcategory.name} ({currentSubcategory && getActiveCalibersCount(currentSubcategory)} /{" "}
              {currentSubcategory && currentSubcategory.values.length})
            </h5>
            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="card-body pt-0" id="modal-html">
            <ul className="nav nav-pills nav-overflow mb-3">
              {calibers &&
                calibers.map((item, i) => (
                  <li key={i} className="nav-item">
                    <button
                      className={`nav-link d-flex align-items-center ${currentSubcategory && currentSubcategory.id === item.id ? "active" : ""}`}
                      id={`filter-${item.id}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#filter-${item.id}-tab-pane`}
                      role="tab"
                      aria-controls={`#filter-${item.id}-tab-pane`}
                      aria-selected="true"
                      onClick={() => setCurrentSubcategory(item)}
                    >
                      <img className="d-none d-md-inline-block lazy img-white me-2" src={item.imageUrl} alt="" height="14" />
                      <span>{item.name}</span>
                    </button>
                  </li>
                ))}
            </ul>
            <div className="tab-content" id="filter-tab-content">
              {calibers &&
                calibers.map((item, i) => (
                  <div
                    className={`tab-pane ${currentSubcategory && currentSubcategory.id === item.id ? "show active" : ""}`}
                    id={`filter-${item.id}-tab-pane`}
                    role="tabpanel"
                    aria-labelledby={`filter-${item.id}-tab`}
                    tabIndex="0"
                    key={i}
                  >
                    <div className="row row-cols-1 row-cols-lg-2 g-2">
                      {item.values.map((caliber, i) => (
                        <div className="col" key={i}>
                          <label className="form-check-label w-100" htmlFor={caliber}>
                            <div className="card card-xs mb-0">
                              <div className="card-body">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    id={caliber}
                                    type="checkbox"
                                    name="calibers"
                                    onClick={() => onSelectedBulletType(caliber)}
                                    checked={isBulletTypeChecked(caliber)}
                                    readOnly={true}
                                  />
                                  {caliber}
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="hstack gap-3 mt-3">
                      <button className="btn btn-lg btn-light w-100" type="clear" onClick={onClearAll}>
                        Clear all
                      </button>
                      <button className="btn btn-lg btn-primary w-100" onClick={onSet}>
                        Set
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
});

export default ProfileView;
