import { makeAutoObservable } from "mobx";
import IOrderItem from "../types/OrderItem";

export class CartStore {
  products: IOrderItem[] = [];
  budget: number;
  isEnteredBudgetLimit: boolean;

  constructor() {
    this.setInitialValues();
    makeAutoObservable(this);
  }

  get totalPrice() {
    var total = 0;
    this.products.forEach((p) => (total += p.package.price * p.quantity));
    return total;
  }

  get remainingBudget() {
    return this.budget - this.totalPrice;
  }

  get totalItems() {
    return this.products.reduce((sum, product) => sum += product.quantity, 0);
  }

  addProduct(product, productPackage, quantity, overwrite) {
    var index = this.products.findIndex(
      (p) =>
        p.product &&
        p.product.id === product.id &&
        p.package.id === productPackage.id
    );
    if (index !== -1) {
      if (overwrite) {
        this.products[index].quantity = quantity;
      } else {
        this.products[index].quantity += quantity;
      }
    } else {
      this.products.push({
        product: product,
        package: productPackage,
        quantity: quantity,
      });
    }
    localStorage.setItem("products", JSON.stringify(this.products));
  }

  copyToCart(products) {
    if (products && products.length > 0) {
      products.forEach((element) => {
        var index = this.products.findIndex(
          (p) => p.product && p.product.id === element.product.id
        );
        if (index !== -1) {
          this.products[index].quantity += element.quantity;
        } else {
          this.products.push({
            product: element.product,
            package: element.package,
            quantity: element.quantity,
          });
        }
      });
      localStorage.setItem("products", JSON.stringify(this.products));
    }
  }

  removeProduct(id, packageId, quantity) {
    var index = this.products.findIndex(
      (p) => p.product && p.product.id === id && p.package.id === packageId
    );
    if (index !== -1) {
      if (quantity === 0) {
        this.products[index].quantity = 0;
      } else {
        this.products[index].quantity -= quantity;
      }
      if (this.products[index].quantity <= 0) {
        this.products.splice(index, 1);
      }
    }

    localStorage.setItem("products", JSON.stringify(this.products));
  }

  clearCart() {
    this.products = [];
    localStorage.removeItem("products");
  }

  clearBudget() {
    this.budget = null;
    this.isEnteredBudgetLimit = null;
    localStorage.removeItem("budget");
    localStorage.removeItem("isEnteredBudgetLimit");
  }

  getQuantity(id, packageId) {
    var index =
      this.products.length > 0
        ? this.products.findIndex(
          (p) => p.product.id === id && p.package.id === packageId
        )
        : -1;
    return index !== -1 ? this.products[index].quantity : 0;
  }

  setBudget(budget, isEnteredBudgetLimit) {
    this.budget = budget;
    this.isEnteredBudgetLimit = isEnteredBudgetLimit;
    localStorage.setItem("budget", budget);
    localStorage.setItem("isEnteredBudgetLimit", isEnteredBudgetLimit);
  }

  setInitialValues() {
    var products = localStorage.getItem("products");
    var budget = localStorage.getItem("budget");
    var isEnteredBudgetLimit = localStorage.getItem("isEnteredBudgetLimit");
    if (products) {
      this.products = JSON.parse(products);
    }
    if (budget) {
      this.budget = JSON.parse(budget);
    }
    if (isEnteredBudgetLimit) {
      this.isEnteredBudgetLimit = JSON.parse(isEnteredBudgetLimit);
    }
  }
}

export default new CartStore();
