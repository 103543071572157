import { makeAutoObservable } from "mobx";
import IProduct from "../types/Product";

export class SearchStore {
  searchTerm: string[] = [];
  searchHistoryProducts: IProduct[] = [];
  selectedProductId: number = null;

  constructor() {
    this.setInitialValues();
    makeAutoObservable(this);
  }

  setSearchTerm(searchTerm) {
    this.searchTerm = searchTerm;
  }

  setSelectedProductId(id) {
    this.selectedProductId = id;
  }

  clearSearch() {
    this.searchTerm = null;
    var searchInput = document.getElementById("search") as HTMLInputElement;
    if (searchInput) {
      searchInput.value = "";
    }
  }

  addToSearchHistory(product) {
    var index = this.searchHistoryProducts.findIndex(
      (p) => p.id === product.id
    );
    if (index !== -1) {
      this.searchHistoryProducts.splice(index, 1);
    }
    this.searchHistoryProducts.splice(0, 0, product);
    localStorage.setItem(
      "searchHistoryProducts",
      JSON.stringify(this.searchHistoryProducts)
    );
  }

  setInitialValues() {
    var searchHistoryProducts = localStorage.getItem("searchHistoryProducts");
    if (searchHistoryProducts) {
      this.searchHistoryProducts = JSON.parse(searchHistoryProducts);
    }
  }
}

export default new SearchStore();
