import { useStore } from "../../stores/StoreContext";
import { observer } from "mobx-react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { formatPrice } from "../../utils/formatters";
import ModalService from "./Modal/ModalService";
import BuyNowModal from "../../modals/BuyNowModal";

export const Cart = observer((props) => {
  const { cartStore } = useStore();

  const percentageRatio = (cartStore.totalPrice / cartStore.budget) * 100;

  const openBuyNowModal = () => {
    ModalService.open(BuyNowModal);
  };

  return (
    <div>
      <div className={`cart-toggle py-1 py-xl-0 row g-0 align-items-center ${cartStore.budget ? "active" : ""}`}>
        <div className="col me-3">
          <a id="cart" className="d-block text-body" href="#cart-collapse" data-bs-toggle="collapse" aria-expanded="false" aria-controls="cart-collapse">
            <span className="row g-0 align-items-center justify-content-center">
              <span className="col-auto me-3 text-center">
                <span className="d-block fs-3 fe fe-cart"></span>
              </span>
              <span className="col">
                <span className="d-flex">
                  <span>
                    <span className="d-block lh-1 fs-8 fw-normal text-muted">Total</span>
                    <span className="lh-1 fs-7 fw-bold text-primary">{formatPrice(cartStore.totalPrice)}</span>
                  </span>
                  <span className={`ms-auto budget-remaining ${cartStore.remainingBudget < 0 ? "text-danger" : ""}`}>
                    <span className={`d-block lh-1 fs-8 fw-normal  ${cartStore.remainingBudget < 0 ? "" : "text-nobel"}`}>{cartStore.remainingBudget < 0 ? "out of budget " : "remaining "}</span>
                    <span className="lh-1 fs-7 fw-bold">
                      {" "}
                      {cartStore.remainingBudget < 0 && <>{formatPrice(Math.abs(cartStore.remainingBudget))}</>}
                      {cartStore.remainingBudget >= 0 && formatPrice(cartStore.remainingBudget)}
                    </span>
                  </span>
                </span>
                <span className="budget-progress">
                  <span className="progress progress-sm">
                    <span
                      className={`progress-bar ${percentageRatio < 60 ? "bg-success" : percentageRatio < 90 ? "bg-warning" : "bg-danger"}`}
                      role="progressbar"
                      style={{ width: `${percentageRatio}%` }}
                      aria-valuenow={percentageRatio}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></span>
                  </span>
                </span>
              </span>
            </span>
          </a>
        </div>
        <div className="col-auto me-3">
          {cartStore.budget && <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip>Set budget</Tooltip>} rootClose={true}>
            <a className="text-body text-center" data-bs-toggle="modal" data-bs-target="#budget-modal" href="#!">
              <span className="d-block fs-3 fe fe-budget"></span>
            </a>
          </OverlayTrigger>}
          {!cartStore.budget && <a className="btn btn-light btn-sm btn-primary-hover text-white-hover border-none px-2 py-1 fs-6" data-bs-toggle="modal" data-bs-target="#budget-modal" href="#!">
            <span>Set Budget</span>
          </a>}
        </div>

        <div className="col-auto">
          {!props.isIndividualUser && (
            <button
              className="btn btn-light btn-sm btn-primary-hover text-white-hover border-none px-2 py-1 fs-6"
              href="#!"
              onClick={props.onQuoteOrder}
              disabled={cartStore.products.length === 0 || props.isLoadingQuoteOrder}
            >
              {props.isLoadingQuoteOrder && (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;{" "}
                </>
              )}
              <span className="fe fe-file-text"></span>
              &nbsp; <span>Create Quote</span>
            </button>
          )}

          {props.isIndividualUser && (
            <button className="btn btn-warning" href="#!" onClick={openBuyNowModal} disabled={cartStore.products.length === 0}>
              <span className="fe fe-credit-card"></span>
              &nbsp; <span>Buy Now</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
});
