import axios from "axios";
import { GET_ALL_PRODUCTS } from "./CONSTANTS";
import IProduct from "../types/Product";
import StoreService from "./StoreService";

export const getAllProducts = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get<Array<IProduct>>(GET_ALL_PRODUCTS())
        .then((res) => {
          resolve(res.data);
        })
        .catch(() => {});
    } catch (error) {}
  });
};

// export const getProductsByParameters = (searchTerm, filters, sortBy) => {
//   return new Promise((resolve, reject) => {
//     try {
//       axios
//         .get<Array<IProduct>>(GET_ALL_PRODUCTS())
//         .then((res) => {
//           if (searchTerm) {
//             res.data = res.data.filter(
//               (d) =>
//                 d.partNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 d.firearm.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 d.purpose.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 d.caliber.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 d.grain.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 d.velocity.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 d.bulletType.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 d.line.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 d.brand.toLowerCase().includes(searchTerm.toLowerCase())
//             );
//           }

//           if (filters) {
//             if (filters.calibers) {
//               var allCalibers = [];
//               filters.calibers.forEach(
//                 (c) => (allCalibers = allCalibers.concat(c.calibers))
//               );
//               res.data = res.data.filter((d) =>
//                 allCalibers.some(
//                   (ac) => ac.toLowerCase() === d.caliber.toLowerCase()
//                 )
//               );
//             }
//           }

//           if (sortBy) {
//             switch (sortBy) {
//               case "manufacturerAsc":
//                 res.data = res.data.sort((a, b) =>
//                   a.brand > b.brand ? 1 : -1
//                 );
//                 break;
//               case "manufacturerDesc":
//                 res.data = res.data.sort((a, b) =>
//                   a.brand < b.brand ? 1 : -1
//                 );
//                 break;
//               case "priceAsc":
//                 res.data = res.data.sort((a, b) =>
//                   a.listPrice > b.listPrice ? 1 : -1
//                 );
//                 break;
//               case "priceDesc":
//                 res.data = res.data.sort((a, b) =>
//                   a.listPrice < b.listPrice ? 1 : -1
//                 );
//                 break;
//               case "purpose":
//                 res.data = res.data.sort((a, b) =>
//                   a.purpose > b.purpose ? 1 : -1
//                 );
//                 break;
//             }
//           }
//           resolve(res.data);
//         })
//         .catch(() => {});
//     } catch (error) {}
//   });
// };

export const getByParameters = (params = {} as any) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post<Array<IProduct>>("https://testapi-commerce.emaq.ba/api/NdssItems", {
          page: params.page ? params.page : 0,
          pageSize: params.pageSize ? params.pageSize : 10,
          retrieveAll: params.retrieveAll ? params.retrieveAll : false,
          includeCount: params.includeCount ? params.includeCount : true,
          orderBy: params.orderBy ? params.orderBy : null,
          additionalData: params.additionalData
            ? params.additionalData
            : {
                includeList: [],
              },
          searchParam: params.searchParam ? params.searchParam : null,
          attributeFilters: params.attributeFilters ? params.attributeFilters : {},
          storeId: StoreService.getStoreId(),
          manufacturerIdList: params.manufacturerIdList ? params.manufacturerIdList : null,
          brandLineIdList: params.brandLineIdList ? params.brandLineIdList : null,
          categoryId: params.categoryId ? params.categoryId : null,
          subcategoryId: params.subcategoryId ? params.subcategoryId : null,
          groupId: params.groupId ? params.groupId : null,
          isFeatured: params.isFeatured ? params.isFeatured : null,
          idList: params.idList ? params.idList : [],
          id: params.id ? params.id : null,
        })
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  });
};

export const getById = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get<Array<IProduct>>("https://testapi-commerce.emaq.ba/api/NdssItems/GetById", { params: { id: id, storeId: StoreService.getStoreId() } })
        .then((res) => {
          var response = res as any;
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  });
};

const ProductService = {
  getAllProducts,
  getByParameters,
  getById,
};

export default ProductService;
