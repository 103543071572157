import axios from "axios";
import ISubcategory from "../types/Subcategory";
import StoreService from "./StoreService";

export const getAllSubcategories = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get<Array<ISubcategory>>("https://testapi-commerce.emaq.ba/api/Categories/CategoriesAndSubcategories?TenantId=4")
        .then((res) => {
          var response = res as any;
          resolve(response.data[0].subcategories);
        })
        .catch(() => {});
    } catch (error) {}
  });
};

export const getAllSubcategoriesWithFilters = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/Attributes/GetFiltersWithValues", { params: { storeId: StoreService.getStoreId() } })
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList[0].subcategories);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  });
};

const SubcategoryService = {
  getAllSubcategories,
  getAllSubcategoriesWithFilters,
};

export default SubcategoryService;
