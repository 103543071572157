import React, { useEffect, useState } from "react";
import initLottie from "../../../utils/lottie";
import { useStore } from "../../../stores/StoreContext";
import { observer } from "mobx-react";
import { hideFlyoutCard } from "../../../utils/flyout";
import debounce from "debounce";
import { formatPrice } from "../../../utils/formatters";
import ProductService from "../../../services/ProductService";
import Highlighter from "react-highlight-words";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export const ProductPreview = observer((props) => {
  const { cartStore } = useStore();
  const [product, setProduct] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      var product = await ProductService.getById(props.id);
      setProduct(product);
      setIsLoading(false);
    };
    fetchData();

    // TODO HASAN - Remove timeout demo only
    var lottieInitTimeout = window.setTimeout(initLottie, 1000);
    return () => {
      clearTimeout(lottieInitTimeout);
    };
  }, [props.id]);

  const addToCart = (productPackage, quantity = 1, overwrite = false) => {
    if (isNaN(quantity))
      return;

    if (quantity == 0) {
      cartStore.removeProduct(product.id, productPackage.id, quantity);
    } else {
      cartStore.addProduct(product, productPackage, quantity, overwrite);
    }
  };

  const removeFromCart = (packageId, quantity = 1) => {
    cartStore.removeProduct(product.id, packageId, quantity);
  };

  const itemCartQuantity = (packageId) => {
    return cartStore.getQuantity(product.id, packageId);
  };

  const isBudgetReached = (price) => {
    return cartStore.isEnteredBudgetLimit && cartStore.budget && cartStore.totalPrice + price >= cartStore.budget;
  };

  const onClose = () => {
    hideFlyoutCard();
  };

  const secondaryAttributesRowCount = 4;
  const secondaryAttributesRow = product && product.secondaryAttributes && product.secondaryAttributes.length > 0 && product.secondaryAttributes.slice(0, secondaryAttributesRowCount);
  const secondaryAttributesOther =
    product && product.secondaryAttributes && product.secondaryAttributes.length > secondaryAttributesRowCount && product.secondaryAttributes.slice(secondaryAttributesRowCount);

  return (
    <div className="d-flex flex-column w-100">
      <div className="content">
        <div className="container">
          {isLoading && (
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border text-primary"
                role="status"
                style={{
                  "--bs-spinner-border-width": "0.15em",
                }}
              ></div>
            </div>
          )}
          {product && (
            <>
              <button className="btn-close position-absolute top-0 end-0 m-2 fs-4 d-xl-none" id="flyout-close" aria-label="Close" onClick={onClose}></button>

              {/* <div className={`card-ribbon-border text-white ${props.item.purpose === "Duty" ? "bg-primary" : "bg-danger"}`}>
            <h6 className="ribbon mb-0 text-uppercase">
              <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={props.item.purpose} />
            </h6>
          </div> */}

              <div className="row align-items-end justify-content-between">
                <div className="col-auto"><p className="my-1 mt-xl-4 fs-7 text-muted">{product.sku}</p></div>
                {(props.purpose &&
                  <div className="col-auto">
                    <div className="me-4 me-md-0">
                      <span className={`badge text-uppercase ${props.purpose == "Duty" ? "text-primary bg-primary" : "text-danger bg-danger"} rounded-pill`} style={{ "--bs-bg-opacity": 0.1 }}>
                        {props.purpose}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <h5 className="mb-1 fw-normal">
                {product.primaryAttributes[0].description && (
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Tooltip>{product.primaryAttributes[0].description}</Tooltip>} rootClose={true}>
                    <span>
                      <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={`${product.primaryAttributes[0].value}`} />
                    </span>
                  </OverlayTrigger>
                )}
                {!product.primaryAttributes[0].description && (
                  <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={`${product.primaryAttributes[0].value}`} />
                )}
                &nbsp;&middot;&nbsp;
                {product.primaryAttributes[1].description && (
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Tooltip>{product.primaryAttributes[1].description}</Tooltip>} rootClose={true}>
                    <span>
                      <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={`${product.primaryAttributes[1].value}`} />
                    </span>
                  </OverlayTrigger>
                )}
                {!product.primaryAttributes[1].description && (
                  <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={`${product.primaryAttributes[1].value}`} />
                )}
                &nbsp;&middot;&nbsp;
                {product.primaryAttributes[2].description && (
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Tooltip>{product.primaryAttributes[2].description}</Tooltip>} rootClose={true}>
                    <span>
                      <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={`${product.primaryAttributes[2].value}`} />
                    </span>
                  </OverlayTrigger>
                )}
                {!product.primaryAttributes[2].description && (
                  <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={`${product.primaryAttributes[2].value}`} />
                )}
              </h5>
              <p className="mb-3 fs-7 text-muted">{product.brandLine} - {product.manufacturer}</p>
              {product.animatedImageUrl && <div id="lottie" className="position-relative">
                <div className="ratio ratio-2x1">
                  <div className="fade" id="lottie-animation" data-file={product.animatedImageUrl}></div>
                </div>

                <div className="position-absolute fade show" id="lottie-spinner" style={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                  <img
                    src={`/data/lottie/first-frame.php?file=${product.animatedImageUrl.substring(product.animatedImageUrl.lastIndexOf('/') + 1)}`}
                    class="h-100 m-auto" />

                  {/* <div className="spinner-border text-primary" role="status" style={{ "--bs-spinner-border-width": "0.15em" }}></div> */}
                </div>
              </div>}

              {!product.animatedImageUrl && props.packageImageUrl && <div className="text-center py-2 py-md-3">
                <img src={props.packageImageUrl} class="w-50" />
              </div>}
              <div className="row gx-2 my-3 text-center border rounded">
                {secondaryAttributesRow &&
                  secondaryAttributesRow.map((item, i) => (
                    <div className={`col-3 py-2 py-xl-2 ${i != secondaryAttributesRow.length - 1 ? "border-end" : ""}`} key={i}>
                      <img className="mb-2" src={item.imageUrl} alt={item.label} width="26"></img>
                      <h6 className="mb-1 fs-7">{item.value}</h6>
                      <p className="mb-0 fs-7 text-muted">{item.label}</p>
                    </div>
                  ))}
              </div>
              <p className="mb-3 text-muted">{product.description}</p>
              <div className="row">
                {secondaryAttributesOther.map((a, i) => {
                  return (
                    <div className="col-6 col-md-4" key={i}>
                      <dl className="mb-0">
                        <dt className="mb-1 fs-7 lh-1 text-black">{a.value}</dt>
                        <dd className="mb-3 fs-8 text-muted">{a.label}</dd>
                      </dl>
                    </div>
                  );
                })}
              </div>

              {/* <div className="accordion accordion-merge accordion-flush" id="product-more-info">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="product-more-info-header">
                    <button className="accordion-button collapsed pt-0 px-0 text-black shadow-none" data-bs-toggle="collapse" data-bs-target="#product-more-info-collapse" aria-expanded="false" aria-controls="product-more-info-collapse">More Information</button>
                  </h2>
                  <div id="product-more-info-collapse" className="accordion-collapse collapse" aria-labelledby="product-more-info-header" data-bs-parent="#product-more-info">
                    <div className="accordion-body p-0">
                      <p className="mb-3 text-muted">{product.description}</p>
                      <div className="row">
                        {secondaryAttributesOther.map((a, i) => {
                          return (
                            <div className="col-6 col-md-4" key={i}>
                              <dl className="mb-0">
                                <dt className="mb-1 fs-7 lh-1 text-black">{a.value}</dt>
                                <dd className="mb-3 fs-8 text-muted">{a.label}</dd>
                              </dl>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </>
          )}
        </div>
      </div>
      <div className="py-2 shadow-lg">
        <div className="container">
          <div className="row">
            {product &&
              product.itemPackages &&
              product.itemPackages.map((item, i) => {
                return (<>
                  <div className="col-12 col-md-6 mb-md-0 border-end">
                    <div className="row align-items-center">
                      <div className="col">
                        <p className="mb-0 text-black fw-semibold fs-6">{item.name}</p>
                        <p className="mb-0 fs-8 text-muted">{item.unitQuantity} {product.measurementUnit}</p>
                      </div>
                      <div className="col-auto">
                        <p className="mb-0 text-black fs-6">{formatPrice(item.price)}</p>
                      </div>
                      <div className="col-4">
                        {itemCartQuantity(item.id) === 0 && (
                          <button
                            className="btn btn-sm btn-white text-muted px-2 w-100"
                            data-bs-toggle="toast"
                            data-bs-target="#product-toast"
                            onClick={() => addToCart(item)}
                            disabled={isBudgetReached(item.price)}
                          >
                            <span className="fe fe-cart me-1"></span><span>Add</span>
                          </button>
                        )}
                        {itemCartQuantity(item.id) !== 0 && (
                          <div className="btn-number d-inline-flex mw-100 w-100">
                            <button className="btn btn-sm btn-link btn-minus px-1" onClick={() => removeFromCart(item.id)}>
                              <span className="fe fe-minus align-middle"></span>
                            </button>
                            <input
                              key={`product-preview-${product.id}-${item.id}-${itemCartQuantity(item.id)}`}
                              className={`form-control form-control-sm form-control-flush px-0 text-center rounded-0 id-${product.id}-${item.id}`}
                              type="number"
                              name="quantity"
                              defaultValue={itemCartQuantity(item.id)}
                              disabled={isBudgetReached(item.price)}
                              onFocus={(e) => e.target.select()}
                              onBlur={(e) => {
                                if (!e.target.value || !e.target.value.length || isNaN(parseInt(e.target.value)))
                                  removeFromCart(item.id);
                              }}
                              onChange={debounce(async (e) => {
                                addToCart(item, parseInt(e.target.value), true);
                              }, 500)}
                              min={0}
                            />
                            <button className="btn btn-sm btn-link btn-plus px-1" onClick={() => addToCart(item)} disabled={isBudgetReached(item.price)}>
                              <span className="fe fe-plus align-middle"></span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {i != product.itemPackages.length - 1 && <div className="col-12 my-2 border-bottom d-md-none"></div>}
                </>);
              })}
          </div>
        </div>
      </div>
    </div>
  );
});
