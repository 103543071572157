function checkMenuStyle() {
  const menu = document.getElementById("menu");

  if (menu) {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (width <= 1440) {
      menu.classList.add("menu-sm");
    } else {
      menu.classList.remove("menu-sm");
    }
  }
}

function initMenu() {
  checkMenuStyle();

  window.addEventListener("resize", checkMenuStyle);
}

function toggleMenu() {
  const menu = document.getElementById("menu");

  menu.classList.add("animated");
  menu.classList.toggle("menu-sm");

  window.setTimeout(function () {
    menu.classList.remove("animated");
  }, 200);
}

export { initMenu, toggleMenu };
