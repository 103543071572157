import { makeAutoObservable } from "mobx";
import IOrder from "../types/Order";
import OrderStatusesService from "../services/OrderStatusesService";
import IOrderStatus from "../types/OrderStatus";
import IOrderActivity from "../types/OrderActivity";
import IDocument from "../types/Document";
import dateFormat from "dateformat";
import IOrderItem from "../types/OrderItem";
import moment from "moment";
import ReportsService from "../services/ReportsService";

export class OrdersStore {
  orders: IOrder[] = [];
  notifier: number = 0;

  constructor() {
    this.setInitialValues();
    makeAutoObservable(this);
  }

  notify(): void {
    this.notifier++;
  }

  get unseenOrdersCount() {
    return this.orders.filter((o) => !o.isSeen).length;
  }

  get unseenDocumentsCount() {
    var allUnseenDocuments = [];
    this.orders.forEach((o) => (allUnseenDocuments = allUnseenDocuments.concat(o.documents.filter((d) => !d.isSeen))));
    return allUnseenDocuments.length;
  }

  get documents() {
    var allDocuments = [];
    this.orders.forEach((o) => (allDocuments = allDocuments.concat(o.documents)));
    return allDocuments;
  }

  getByParameters = (searchTerm, filter, sortBy) => {
    var orders = [...this.orders];
    if (filter) {
      if (filter.orderStatuses && filter.orderStatuses.length > 0) {
        orders = orders.filter((o) => filter.orderStatuses.some((os) => os.toLowerCase() === o.currentStatus.toLowerCase()));
      }
      if (filter.dateFrom) {
        orders = orders.filter((o) => moment(o.date, "MM-DD-YYYY hh:mm A") > filter.dateFrom);
      }
      if (filter.dateTo) {
        orders = orders.filter((o) => moment(o.date, "MM-DD-YYYY hh:mm A") < filter.dateTo);
      }
    }

    if (searchTerm) {
      searchTerm = searchTerm.toLowerCase();
      orders = orders.filter((o) => o.currentStatus.toLowerCase().includes(searchTerm) || o.orderNumber.toLowerCase().includes(searchTerm));
    }

    if (sortBy) {
      switch (sortBy) {
        case "dateAsc":
          orders = orders.sort((a, b) => (moment(a.date, "MM-DD-YYYY hh:mm A") > moment(b.date, "MM-DD-YYYY hh:mm A") ? 1 : -1));
          break;
        case "dateDesc":
          orders = orders.sort((a, b) => (moment(a.date, "MM-DD-YYYY hh:mm A") < moment(b.date, "MM-DD-YYYY hh:mm A") ? 1 : -1));
          break;
      }
    }
    return orders;
  };

  getDocumentsByParameters = (searchTerm, filter, sortBy) => {
    var documents = [...this.documents];
    if (filter) {
      if (filter.documentTypes && filter.documentTypes.length > 0) {
        documents = documents.filter((d) => filter.documentTypes.some((dt) => dt.toLowerCase() === d.type.toLowerCase()));
      }
      if (filter.dateFrom) {
        documents = documents.filter((o) => {
          return moment(o.date, "MM-DD-YYYY hh:mm A") > filter.dateFrom;
        });
      }
      if (filter.dateTo) {
        documents = documents.filter((o) => {
          return moment(o.date, "MM-DD-YYYY hh:mm A") < filter.dateTo;
        });
      }
    }

    if (searchTerm) {
      searchTerm = searchTerm.toLowerCase();
      documents = documents.filter((d) => d.type.toLowerCase().includes(searchTerm) || d.documentNumber.toLowerCase().includes(searchTerm));
    }
    if (sortBy) {
      switch (sortBy) {
        case "dateAsc":
          documents = documents.sort((a, b) => (moment(a.date, "MM-DD-YYYY hh:mm A") > moment(b.date, "MM-DD-YYYY hh:mm A") ? 1 : -1));
          break;
        case "dateDesc":
          documents = documents.sort((a, b) => (moment(a.date, "MM-DD-YYYY hh:mm A") < moment(b.date, "MM-DD-YYYY hh:mm A") ? 1 : -1));
          break;
      }
    }
    return documents;
  };

  getByOrderNumber = (orderNumber) => {
    var index = this.orders.findIndex((o) => o.orderNumber === orderNumber);
    if (index !== -1) {
      return this.orders[index];
    }
    return;
  };

  getDocumentByDocumentNumber = (documentNumber) => {
    var index = this.documents.findIndex((o) => o.documentNumber === documentNumber);
    if (index !== -1) {
      return this.documents[index];
    }
    return;
  };

  async createOrder(products: IOrderItem[], action, pOrderNumber = null, pOrderDate = null) {
    const orderStatuses = (await OrderStatusesService.getOrderStatusesByActionType(action)) as IOrderStatus[];

    var orderNumber = pOrderNumber ? pOrderNumber : `#${Date.now().toString()}`;
    var date = new Date();
    var orderDate = pOrderDate ? pOrderDate : dateFormat(date, "mm-dd-yyyy hh:MM TT");
    var validUntil = new Date(date);
    validUntil.setDate(validUntil.getDate() + 30);
    var validUntilDate = dateFormat(validUntil, "mm-dd-yyyy hh:MM TT");

    var quotePDFUrl = "/docs/quote.pdf";
    var purchaseOrderPDFUrl = "/docs/purchase_order.pdf";
    var exemptionCertificateUrl = "/docs/exemption_certificate.pdf";

    var activities: IOrderActivity[] = [];
    orderStatuses.forEach((os) => activities.push({ status: os.name, completed: false }));

    if (activities && activities.length > 0) {
      activities[0].date = orderDate;
      activities[0].completed = true;
    }

    var documents: IDocument[] = [];

    documents.unshift({
      documentNumber: `#${Date.now().toString()}`,
      date: orderDate,
      type: action === "quote" ? "Quoted" : "Purchase Order",
      url: action === "quote" ? quotePDFUrl : purchaseOrderPDFUrl,
      isSeen: false,
      isSigned: action !== "quote",
    });

    if (action === "purchase") {
      documents.unshift({
        documentNumber: `#${(Date.now() + 1).toString()}`,
        date: orderDate,
        type: "Exemption certificate",
        url: exemptionCertificateUrl,
        isSeen: false,
        isSigned: true,
      });

      this.setTimeoutToShippedStatus(orderNumber);
    }

    var totalPrice = 0;
    products.forEach((p) => (totalPrice += p.product.price * p.quantity));

    this.orders.unshift({
      orderNumber: orderNumber,
      date: orderDate,
      validUntilDate: validUntilDate,
      products: products,
      activities: activities,
      documents: documents,
      totalPrice: totalPrice,
      isSeen: false,
    });

    this.setCurrentStatus();
    localStorage.setItem("orders", JSON.stringify(this.orders));

    return orderNumber;
  }

  async buyNow(products: IOrderItem[], action) {
    const orderStatuses = (await OrderStatusesService.getOrderStatusesByActionType(action)) as IOrderStatus[];

    var orderNumber = `#${Date.now().toString()}`;
    var orderDate = dateFormat(new Date(), "mm-dd-yyyy hh:MM TT");

    var activities: IOrderActivity[] = [];
    orderStatuses.forEach((os) => activities.push({ status: os.name, completed: false }));

    if (activities && activities.length > 0) {
      activities[0].date = orderDate;
      activities[0].completed = true;
    }

    var documents: IDocument[] = [];

    documents.unshift({
      documentNumber: `#${Date.now().toString()}`,
      date: orderDate,
      type: "Purchase Order",
      url: "/docs/purchase_order.pdf",
      isSeen: false,
      isSigned: action !== "quote",
    });

    documents.unshift({
      documentNumber: `#${(Date.now() + 1).toString()}`,
      date: orderDate,
      type: "Exemption certificate",
      url: "/docs/exemption_certificate.pdf",
      isSeen: false,
      isSigned: true,
    });

    documents.unshift({
      documentNumber: `#${(Date.now() + 3).toString()}`,
      date: dateFormat(new Date(), "mm-dd-yyyy hh:MM TT"),
      type: "Invoice",
      url: "/docs/invoice.pdf",
      isSeen: false,
      isSigned: true,
    });

    var totalPrice = 0;
    products.forEach((p) => (totalPrice += p.product.price * p.quantity));

    this.orders.unshift({
      orderNumber: orderNumber,
      date: orderDate,
      products: products,
      activities: activities,
      documents: documents,
      totalPrice: totalPrice,
      isSeen: false,
    });

    this.setCurrentStatus();
    localStorage.setItem("orders", JSON.stringify(this.orders));
  }

  async purchaseOrder(orderNumber: string) {
    var index = this.orders.findIndex((o) => o.orderNumber === orderNumber);
    if (index !== -1) {
      if (this.orders[index].currentStatus !== "QUOTED") return;

      var documentDate = dateFormat(new Date(), "mm-dd-yyyy hh:MM TT");
      this.orders[index].documents.unshift(
        {
          documentNumber: `#${Date.now().toString()}`,
          date: documentDate,
          type: "Purchase Order",
          url: "/docs/purchase_order.pdf",
          isSeen: false,
          isSigned: true,
        },
        {
          documentNumber: `#${(Date.now() + 1).toString()}`,
          date: documentDate,
          type: "Exemption certificate",
          url: "/docs/exemption_certificate.pdf",
          isSeen: false,
          isSigned: true,
        }
      );

      var activity = this.orders[index].activities.find((a) => a.completed === false);
      if (activity) {
        activity.date = dateFormat(new Date(), "mm-dd-yyyy hh:MM TT");
        activity.completed = true;
        this.setCurrentStatus();
        localStorage.setItem("orders", JSON.stringify(this.orders));
        this.setTimeoutToShippedStatus(orderNumber);
      }
    }
  }

  setCurrentStatus() {
    this.orders.forEach((o) => {
      var indexOflastCompletedActivity = o.activities && o.activities.map((a) => a.completed).lastIndexOf(true);
      if (indexOflastCompletedActivity !== -1) {
        o.currentStatus = o.activities[indexOflastCompletedActivity].status.toUpperCase();
      }
    });
  }

  setTimeoutToShippedStatus(orderNumber: string) {
    setTimeout(async () => {
      var index = this.orders.findIndex((o) => o.orderNumber === orderNumber);
      if (index !== -1) {
        var activity = this.orders[index].activities.find((a) => a.completed === false);
        if (activity) {
          activity.date = dateFormat(new Date(), "mm-dd-yyyy hh:MM TT");
          activity.completed = true;
          this.setCurrentStatus();
        }
        this.orders[index].documents.unshift({
          documentNumber: `#${Date.now().toString()}`,
          date: dateFormat(new Date(), "mm-dd-yyyy hh:MM TT"),
          type: "Invoice",
          url: this.orders[index].invoiceUrl,
          isSeen: false,
          isSigned: true,
        });
        localStorage.setItem("orders", JSON.stringify(this.orders));
        await ReportsService.shippedEmail();
      }
    }, 10000);
  }

  markAsSeen(orderNumber) {
    var order = this.getByOrderNumber(orderNumber);
    if (orderNumber) {
      order.isSeen = true;
      localStorage.setItem("orders", JSON.stringify(this.orders));
    }
  }

  markDocumenAsSeen(documentNumber) {
    var document = this.getDocumentByDocumentNumber(documentNumber);
    if (document) {
      document.isSeen = true;
      localStorage.setItem("orders", JSON.stringify(this.orders));
    }
  }

  setInitialValues() {
    const orders = localStorage.getItem("orders");
    if (orders) {
      this.orders = JSON.parse(orders);
    }
  }
}

export default new OrdersStore();
