import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import Orders from "../pages/Orders";
import Reports from "../pages/Reports";
import Documents from "../pages/Documents";
import Profile from "../pages/Profile";
import Cart from "../pages/Cart";

import { NotFound } from "../navigation/NotFound";
import { ROOT, PROFILE, ORDERS, REPORTS, DOCUMENTS, SIGN_IN, SIGN_UP, FORGOT_PASSWORD, CART } from "../navigation/CONSTANTS";
import ProtectedRoute from "./Auth/ProtectedRoute";
import SignInView from "../pages/SignIn";
import SignUpContainer from "../pages/SignUp";
import { ForgotPasswordView } from "../pages/SignIn/ForgotPasswordView";
import { Layout } from "../pages/_Layout/Layout";

export const RouterConfig = () => {
  return (
    <div>
      <Routes>
        {/* Public routes */}

        <Route exact path={SIGN_IN} element={<SignInView />} />
        <Route exact path={SIGN_UP} element={<SignUpContainer />} />
        <Route exact path={FORGOT_PASSWORD} element={<ForgotPasswordView />} />

        <Route
          path={ROOT}
          element={
            <ProtectedRoute>
              <Layout></Layout>
            </ProtectedRoute>
          }
        >
          <Route exact path={ROOT} element={<Home />} />
          <Route exact path={ORDERS} element={<Orders />} />
          <Route exact path={REPORTS} element={<Reports />} />
          <Route exact path={DOCUMENTS} element={<Documents />} />
          <Route path={CART} element={<Cart />} />

          <Route exact path={PROFILE} element={<Profile />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};
