import React from "react";
import { formatPrice } from "../../../utils/formatters";
import { observer } from "mobx-react";
import Highlighter from "react-highlight-words";
import moment from "moment";

export const OrderItemComponent = observer((props) => {
  const orderDateFormatted = moment(new Date(props.order.orderDate)).format("DD-MM-YYYY hh:mm A");
  const deliveryDateFormatted = props.order.deliveryDate && moment(new Date(props.order.deliveryDate)).format("DD-MM-YYYY hh:mm A");
  const estimatedDeliveryDateFormatted = props.order.estimatedDeliveryDate && moment(new Date(props.order.estimatedDeliveryDate)).format("DD-MM-YYYY hh:mm A");

  return (
    <div className="col-12">
      <div
        id={`card-${props.index}`}
        data-index={props.index}
        className={`card card-xs card-md-sm card-lift card-lift-expand mb-0 h-100 rounded-1 shadow-none ${props.selected ? "selected" : ""} ${!props.order.isSeen ? "not-seen" : ""}`}
      >
        <a id={`show-details-btn-${props.index}`} className="card-body lh-1" href="#!" onClick={() => props.onSelectedItem(props.order.id)} data-toggle="flyout" title={props.order.orderNumber}>
          <span className="row align-items-center mb-2">
            <span className="col">
              <span className="d-flex align-items-center">
                <span className="h6 mb-0 lh-1 me-2">
                  <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={props.order.orderNumber} />
                </span>
                <span className="badge badge-sm fw-semibold text-nobel border border-nobel rounded-pill">
                  <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={props.order.orderStatusName.toUpperCase()} />
                </span>

                {(props.order.paymentStatusId && props.order.orderStatus !== "Quoted") && (
                  <span className={`badge badge-sm fw-semibold text-success border border-success rounded-pill`}>
                    {" "}
                    <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={props.order.paymentStatusName.toUpperCase()} />
                  </span>
                )}
              </span>
            </span>
            <span className="col-auto">
              <span className="fw-semibold">{formatPrice(props.order.totalPrice)}</span>
            </span>
          </span>
          <span className="row align-items-end">
            <span className="col">
              <span className="d-block fs-9 text-truncate text-muted mb-1">ORDER DATE</span>
              <span className={`d-block fs-8 text-truncate ${props.order.estimatedDeliveryDate && "mb-2"}`}>
                <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={orderDateFormatted} />
              </span>
              {estimatedDeliveryDateFormatted && (
                <>
                  <span className="d-block fs-9 text-truncate text-muted mb-1">EST. DELIVERY DATE</span>
                  <span className="d-block fs-8 text-truncate">
                    <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={estimatedDeliveryDateFormatted} />
                  </span>
                </>
              )}
              {deliveryDateFormatted && (
                <>
                  <span className="d-block fs-9 text-truncate text-muted mb-1">DELIVERY DATE</span>
                  <span className="d-block fs-8 text-truncate">
                    <Highlighter searchWords={props.searchTerm ? props.searchTerm : [""]} autoEscape={true} textToHighlight={deliveryDateFormatted} />
                  </span>
                </>
              )}
            </span>
            <span className="col-auto">
              <span className="row g-1">
                {props.order.orderDocuments &&
                  props.order.orderDocuments.map((od, i) => (
                    <img key={i} className="col-auto" src={`./assets/img/orders/${od.template.toLowerCase()}${od.isSignatureRequired && !od.signedOnDate ? "-unsigned" : ""}.svg`} alt="" height="40" />
                  ))}
              </span>
            </span>
          </span>
        </a>
      </div>
    </div>
  );
});

export default OrderItemComponent;
