import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { handleValidatePhoneNumber, handleValidateEmail } from "../../utils/regex";
import PasswordInput from "../../components/PasswordInput";

const ContactStepView = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [generalError, setGeneralError] = useState();

  useEffect(() => {
    if (props.formData) {
      setValue("email", props.formData.email);
    }
  }, [props.formData]);

  useEffect(() => {
    setGeneralError();
    if (props.errors) {
      props.errors.forEach((err) => {
        if (err.key === "Email") {
          setError("email", { message: err.description });
        } else if (err.key === "Phone") {
          setError("phone", { message: err.description });
        } else if (err.key === "Password") {
          setError("password", { message: err.description });
        } else if (err.key === "PasswordConfirmation") {
          setError("passwordConfirmation", { message: err.description });
        } else {
          setGeneralError(err.description);
        }
      });
    }
  }, [props.errors]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(props.onSubmitStep3)}>
        <div className="row mb-2">
          <div className="col-12 col-md-6">
            <div className="mb-2 mb-md-0">
              <label className="form-label" htmlFor="email">
                E-mail address
              </label>
              <div className="input-group input-group-merge input-group-reverse">
                <input
                  id="email"
                  placeholder="john.smith@example.com"
                  type="email"
                  aria-label="john.smith@example.com"
                  aria-describedby="email-user"
                  className={`form-control form-control-lg ${errors.email ? "border-danger" : ""}`}
                  {...register("email", {
                    required: "E-mail Address is required.",
                    validate: (value) => (handleValidateEmail(value && value.trim()) ? true : "E-mail Address is not in valid format."),
                  })}
                  disabled={props.formData && props.formData.email}
                />
                <div id="email-user" className={`input-group-text ${errors.email ? "border-danger" : ""}`}>
                  <i className="fe fe-at-sign"></i>
                </div>
              </div>
              {errors.email && <small className="text-danger mb-3">{errors.email.message}</small>}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-0">
              <label className="form-label" htmlFor="phone-number">
                Phone number
              </label>
              <div className="input-group input-group-merge input-group-reverse">
                <input
                  id="phone-number"
                  placeholder="(860) 426-9886"
                  type="phone"
                  aria-label="(860) 426-9886"
                  aria-describedby="phone-number-phone"
                  className={`form-control form-control-lg ${errors.phone ? "border-danger" : ""}`}
                  {...register("phone", {
                    required: "Phone is required.",
                    validate: (value) => (handleValidatePhoneNumber(value) ? true : "Phone is not in valid format."),
                  })}
                />
                <div id="phone-number-phone" className={`input-group-text ${errors.phone ? "border-danger" : ""}`}>
                  <i className="fe fe-phone"></i>
                </div>
              </div>
              {errors.phone && <small className="text-danger mb-3">{errors.phone.message}</small>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="mb-2 mb-md-0">
              <label className="form-label" htmlFor="password">
                Password
              </label>
              <PasswordInput
                id="password"
                name="password"
                autoComplete="off"
                placeholder="Password"
                inputGroupClassName={errors.password ? "" : "mb-3"}
                iconClassName={errors.password && "border-danger text-danger"}
                inputClassName={errors.password && "border-danger"}
                formOptions={register("password", {
                  required: "Password is required.",
                  minLength: {
                    value: 8,
                    message: "Password should be at least 8 characters.",
                  },
                })}
              />
              {errors.password && <small className="text-danger mb-3">{errors.password.message}</small>}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-0">
              <label className="form-label" htmlFor="passwordConfirmation">
                Password confirmation
              </label>
              <PasswordInput
                id="passwordConfirmation"
                name="passwordConfirmation"
                autoComplete="off"
                placeholder="Password confirmation"
                inputGroupClassName={errors.passwordConfirmation ? "" : "mb-3"}
                iconClassName={errors.passwordConfirmation && "border-danger text-danger"}
                inputClassName={errors.passwordConfirmation && "border-danger"}
                formOptions={register("passwordConfirmation", {
                  required: "Password confirmation is required.",
                  validate: (value) => {
                    if (watch("password") !== value) {
                      return "Your passwords do not match.";
                    }
                  },
                })}
              />
              {errors.passwordConfirmation && <small className="text-danger mb-3">{errors.passwordConfirmation.message}</small>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* <p className="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat nisi at nibh fermentum, in ultrices ipsum pellentesque. Sed quis libero sed mi pellentesque tempus sed nec nunc.
            </p> */}
            {generalError && <small className="text-danger mb-3">{generalError}</small>}
          </div>
        </div>
        <div className="nav row align-items-center mt-4">
          <div className="col">
            <button className="btn btn-lg btn-white" onClick={props.onBack} type="button">
              Back
            </button>
          </div>
          <div className="col-auto">
            <button className="btn btn-lg btn-primary" type="submit" disabled={props.isLoading}>
             {props.isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
             &nbsp; Complete
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default ContactStepView;
