import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import initLoader from "../../utils/loader";
import { initCart } from "../../utils/cart";
import initChoices from "../../utils/choices";
import { Menu } from "./Menu";
import { Search } from "./Search";
import { Cart } from "./Cart";
import { useStore } from "../../stores/StoreContext";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { closeActiveModal } from "../../components/Modal";
import debounce from "debounce";
import { formatPrice } from "../../utils/formatters";
import { toggleCart } from "../../utils/cart";
import { Sort } from "./Sort";
import { Filter } from "./Filter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserService from "../../services/UserService";
import OrdersService from "../../services/OrdersService";
import ModalService from "./Modal/ModalService";
import BuyNowModal from "../../modals/BuyNowModal";
import PurchaseOrderModal from "../../modals/PurchaseOrderModal";
import QuoteOrderModal from "../../modals/QuoteOrderModal";

export const Layout = observer(() => {
  const { cartStore, productsStore } = useStore();
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });

  const [isCurrentUserIndividual, setIsCurrentUserIndividual] = useState();

  const [isLoadingQuoteOrder, setIsLoadingQuoteOrder] = useState(false);
  const [isCartPage, setIsCartPage] = useState(false);
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [isReportsPage, setIsReportsPage] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsCartPage(location.pathname && location.pathname.toLowerCase().includes("cart"));
    setIsProfilePage(location.pathname && location.pathname.toLowerCase().includes("profile"));
    setIsReportsPage(location.pathname && location.pathname.toLowerCase().includes("reports"));
  }, [location.pathname]);

  useEffect(() => {
    initLoader();
    initCart();
    initChoices();

    const relationship = UserService.getCurrentUserRelationship();
    setIsCurrentUserIndividual(relationship === "Self");
  }, []);

  const addToCart = (id, productPackage, quantity = 1, overwrite = false) => {
    if (isNaN(quantity)) return;

    if (quantity === 0) {
      cartStore.removeProduct(id, productPackage.id, quantity);
    } else {
      cartStore.addProduct({ id: id }, productPackage, quantity, overwrite);
    }
  };

  const removeFromCart = (id, packageId, quantity = 1) => {
    cartStore.removeProduct(id, packageId, quantity);
  };

  const itemCartQuantity = (id, packageId) => {
    return cartStore.getQuantity(id, packageId);
  };

  const validateMinBudget = () => {
    var amount = getValues("amount");
    var isEnteredBudgetLimit = getValues("isEnteredBudgetLimit");
    if (amount) {
      return !isEnteredBudgetLimit || amount >= cartStore.totalPrice;
    }
  };

  const onQuoteOrder = async () => {
    const totalPrice = cartStore.totalPrice;

    setIsLoadingQuoteOrder(true);
    const data = await OrdersService.quoteOrder(cartStore.products);
    setIsLoadingQuoteOrder(false);
    if (data.id) {
      ModalService.open(QuoteOrderModal, {
        quoteOrderNumber: data.orderNumber,
        quoteOrderDocuments: data.documents,
        onPurchaseOrder: () => {
          ModalService.open(PurchaseOrderModal, { orderId: data.id, totalPrice });
        },
      });
      toggleCart();
      cartStore.clearCart();
    }
  };

  const onClearAll = () => {
    cartStore.clearCart();
    toast.success("Cart is successfully cleared!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // const onPrint = async () => {
  //   if (getValuesPurchaseOrder("purchaseOrderDocument") && getValuesPurchaseOrder("exemptionCertificateDocument")) {
  //     const mergedPdf = await PDFDocument.create();
  //     var bytesA = await fetch(purchaseOrderDocUrl).then((res) => res.arrayBuffer());
  //     var bytesB = await fetch(exemptionDocUrl).then((res) => res.arrayBuffer());
  //     const pdfA = await PDFDocument.load(bytesA, { ignoreEncryption: true });
  //     const pdfB = await PDFDocument.load(bytesB, { ignoreEncryption: true });
  //     const copiedPagesA = await mergedPdf.copyPages(pdfA, pdfA.getPageIndices());
  //     copiedPagesA.forEach((page) => mergedPdf.addPage(page));
  //     const copiedPagesB = await mergedPdf.copyPages(pdfB, pdfB.getPageIndices());
  //     copiedPagesB.forEach((page) => mergedPdf.addPage(page));
  //     const mergedPdfFile = await mergedPdf.save();
  //     const blob = new Blob([mergedPdfFile], { type: "application/pdf" });
  //     const url = window.URL.createObjectURL(blob);
  //     print({
  //       printable: url,
  //       type: "pdf",
  //     });
  //   } else if (getValuesPurchaseOrder("purchaseOrderDocument")) {
  //     print(purchaseOrderDocUrl);
  //   } else if (getValuesPurchaseOrder("exemptionCertificateDocument")) {
  //     print(exemptionDocUrl);
  //   }
  // };

  const toggleProductsLayout = () => {
    productsStore.toggleLayout();
  };

  const onSetBudget = (data) => {
    cartStore.setBudget(data.amount, data.isEnteredBudgetLimit);
    closeActiveModal();
  };

  const onClearBudget = () => {
    cartStore.clearBudget();
    setValue("amount", "");
    closeActiveModal();
  };

  const isBudgetReached = (price) => {
    return cartStore.isEnteredBudgetLimit && cartStore.budget && cartStore.totalPrice + price >= cartStore.budget;
  };

  const openBuyNowModal = () => {
    ModalService.open(BuyNowModal);
  };

  // const openPurchaseOrderModal = () => {
  //   ModalService.open(PurchaseOrderModal);
  // };

  return (
    <div>
      <ToastContainer />
      <Menu toggleCart={toggleCart}></Menu>
      <div className={`main position-relative ${location.pathname === "/cart" ? "p-0" : ""}`}>
        <input type="hidden" id="quote-order-modal-opener" data-bs-toggle="modal" data-bs-target="#quote-order-modal" />
        <input type="hidden" id="purchase-order-modal-opener" data-bs-toggle="modal" data-bs-target="#purchase-order-modal" />
        <nav className={`topbar fixed-top d-flex align-items-xl-center bg-white border-bottom ${isCartPage ? "d-none" : ""}`} style={{ zIndex: 0.5 }}>
          <div className="container-fluid">
            <div className="row g-0 align-items-center">
              <div className="col-12 col-xl-6">
                <div className="row gx-2 gx-xl-3 align-items-center my-1 my-lg-0">
                  <div className="col-auto d-xl-none">
                    <a className="box box-sm" href="#!">
                      <img className="box-img" src="./assets/img/brand/logo-sm.png" alt="" />
                    </a>{" "}
                  </div>
                  <div className={`col ${isProfilePage || isReportsPage ? "d-none" : ""}`}>
                    <Search></Search>
                  </div>
                  <div className={`col-auto filters ${isProfilePage || isReportsPage ? "d-none" : ""}`}>
                    <div className="row g-2 g-xl-3 align-items-center">
                      {location.pathname === "/" && (
                        <div className="col-auto">
                          <OverlayTrigger overlay={<Tooltip>{productsStore.layout === "row" ? "Change to card layout" : "Change to list layout"}</Tooltip>} placement="bottom" rootClose={true}>
                            <a className="text-body text-center" href="#!" onClick={toggleProductsLayout}>
                              {productsStore.layout === "row" ? <span className="d-block fs-5 fe fe-layout-grid"></span> : <span className="d-block fs-5 fe fe-list"></span>}
                            </a>
                          </OverlayTrigger>
                        </div>
                      )}
                      <div className="col-auto">
                        <Sort></Sort>
                      </div>
                      {/* Since it's not done for orders we are hiding filters */}
                      {location.pathname && (location.pathname === "/" || location.pathname.toLowerCase().includes("/orders")) && (
                        <div className="col-auto">
                          <Filter></Filter>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 d-none d-xl-block">
                <Cart isIndividualUser={isCurrentUserIndividual} onQuoteOrder={onQuoteOrder} isLoadingQuoteOrder={isLoadingQuoteOrder}></Cart>
              </div>
            </div>
          </div>
        </nav>
        <div className="cart cart-">
          <div className="collapse cart-collapse zi-2 bg-transparent" id="cart-collapse">
            <div className="card mb-0 border-top-0 rounded-0">
              <div className="card-body" style={{ maxHeight: "60vh", overflowY: "auto" }}>
                <div className="list-group list-group-flush">
                  {!cartStore.products || (cartStore.products.length === 0 && <span className="fw-normal text-muted">Add product(s) to cart to be shown here. </span>)}

                  {cartStore.products &&
                    cartStore.products.map((item, i) => {
                      let prefix = item.package?.prefix ?? item.product?.package?.prefix ?? null;
                      let suffix = item.package?.suffix ?? item.product?.package?.suffix ?? null;

                      return (
                        <div className="list-group-item" key={i}>
                          <div className="row g-0 align-items-center">
                            <div className="col">
                              <p className="mb-0 fs-8 text-muted">
                                {prefix ? (
                                  <>
                                    <strong>{prefix}</strong> |{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                                {item.product.sku}
                                {suffix ? (
                                  <>
                                    {" "}
                                    | <strong>{suffix}</strong>
                                  </>
                                ) : (
                                  ""
                                )}
                              </p>
                              <h6 className="mb-0 fs-7">
                                {item.product.manufacturer} {item.product.primaryAttributes[0].value} &middot; {item.product.primaryAttributes[1].value} &middot;{" "}
                                {item.product.primaryAttributes[2].value}
                              </h6>
                            </div>
                            <div className="col-auto ms-1 ms-xl-3 text-center">
                              <div className="btn-number d-inline-flex text-dark bg-light rounded-pill">
                                <button className="btn btn-sm btn-link btn-minus ps-1 pe-0" onClick={() => removeFromCart(item.product.id, item.package.id)}>
                                  <span className="fe fe-minus align-middle"></span>
                                </button>
                                <input
                                  key={`cart-${item.product.id}-${itemCartQuantity(item.product.id, item.package.id)}`}
                                  className={`form-control form-control-sm form-control-flush px-0 text-center id-${item.product.id}-${item.package.id}`}
                                  type="number"
                                  name="quantity"
                                  defaultValue={itemCartQuantity(item.product.id, item.package.id)}
                                  disabled={isBudgetReached(item.package.price)}
                                  onFocus={(e) => e.target.select()}
                                  onBlur={(e) => {
                                    if (!e.target.value || !e.target.value.length || isNaN(parseInt(e.target.value))) removeFromCart(item.product.id, item.package.id);
                                  }}
                                  onChange={debounce(async (e) => {
                                    addToCart(item.product.id, item.package, parseInt(e.target.value), true);
                                  }, 500)}
                                  min={0}
                                />
                                <button className="btn btn-sm btn-link btn-plus ps-0 pe-1" onClick={() => addToCart(item.product.id, item.package)} disabled={isBudgetReached(item.package.price)}>
                                  <span className="fe fe-plus align-middle"></span>
                                </button>
                              </div>
                            </div>
                            <div className="col-2 col-md-2 text-end">
                              <p className="mb-0 fs-7 text-muted">
                                {item.package.unitQuantity * itemCartQuantity(item.product.id, item.package.id)} {item.product.measurementUnit}
                              </p>
                              <p className="mb-0 fs-6 fw-bold">{formatPrice(item.package.price * item.quantity)}</p>
                            </div>
                            <div className="col-auto me-n2">
                              <button className="btn btn-link text-danger" onClick={() => removeFromCart(item.product.id, item.package.id, 0)}>
                                <i className="fe fe-x"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="card-footer">
                <div className="row row-cols-2 row-cols-xxl-4 g-2">
                  {!isCurrentUserIndividual && (
                    <div className="col">
                      <button className="btn btn-warning btn-sm w-100" href="#!" onClick={openBuyNowModal} disabled={cartStore.products.length === 0}>
                        <span className="fe fe-credit-card"></span>
                        &nbsp; <span>Buy Now</span>
                      </button>
                    </div>
                  )}
                  <div className="col ms-auto">
                    <button className="btn btn-light btn-sm w-100" disabled={cartStore.products.length === 0} onClick={onClearAll}>
                      <span className="fe fe-x"></span>
                      &nbsp; <span>Clear All</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a className="cart-backdrop modal-backdrop fade" id="cart-backdrop" href="#cart-collapse" data-bs-toggle="collapse" aria-expanded="true" aria-controls="cart-collapse">
            &nbsp;
          </a>
        </div>
        {!isCartPage && !isProfilePage ? (
          <div className="row row-grid g-0">
            <Outlet></Outlet>
          </div>
        ) : (
          <Outlet></Outlet>
        )}
      </div>
      <div className="loader position-fixed top-0 start-0 d-flex align-items-center justify-content-center vw-100 vh-100 zi-popover bg-white" id="loader">
        <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}></div>
      </div>
      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <div className="toast text-white bg-success border-0 shadow-none" id="product-toast" data-bs-dismiss="toast" aria-label="Close" role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-body">
            <div className="row g-0 align-items-center">
              <div className="col-auto">
                <img src="./assets/img/products/handgun-caliber-1.png" alt="" />
              </div>
              <div className="col ms-2">
                <p className="mb-0 fs-7">
                  You have successfuly added <span className="fw-bold">Speer LE 223 Rem</span> to cart.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="budget-modal" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-card card mb-0 border-0 shadow-none">
              <div className="card-header border-0">
                <h5 className="card-header-title">Set Your Budget</h5>
                <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="card-body pt-0">
                <form onSubmit={handleSubmit(onSetBudget)}>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="amount">
                      Amount
                    </label>
                    <div className="input-group input-group-merge input-group-reverse">
                      <input
                        id="amount"
                        placeholder="0.00"
                        defaultValue={cartStore.budget}
                        type="number"
                        aria-label="0.00"
                        aria-describedby="amount-dollar"
                        className={`form-control form-control-lg ${errors.amount ? "border-danger" : ""}`}
                        {...register("amount", {
                          required: true,
                          min: 1,
                          valueAsNumber: true,
                          validate: { minBudget: validateMinBudget },
                        })}
                        onChange={debounce(async (e) => {
                          await trigger("amount");
                        }, 500)}
                      />
                      <div id="amount-dollar" className={`input-group-text ${errors.amount ? "border-danger" : ""}`}>
                        <i className="fe fe-dollar-sign"></i>
                      </div>
                    </div>
                    {errors.amount && errors.amount.type === "required" && <small className="text-danger">Amount is required.</small>}
                    {errors.amount && errors.amount.type === "valueAsNumber" && <small className="text-danger">Amount must be a number.</small>}
                    {errors.amount && errors.amount.type === "minBudget" && <small className="text-danger">Budget is lower than the cart.</small>}
                    {errors.amount && errors.amount.type === "min" && <small className="text-danger">Budget cannot be negative value.</small>}

                    <div className="form-check mt-1">
                      <input className="form-check-input rounded" id="tax-exempt" type="checkbox" defaultChecked={cartStore.isEnteredBudgetLimit} {...register("isEnteredBudgetLimit")} />
                      <label className="form-check-label" htmlFor="tax-exempt">
                        Entered budget is a limit
                      </label>
                    </div>
                  </div>
                  <div className="row g-2 mt-3">
                    <div className="col">
                      <button className="btn btn-lg btn-light col-12" type="button" onClick={onClearBudget}>
                        <span className="fe fe-x"></span> Clear
                      </button>
                    </div>
                    <div className="col">
                      <button className="btn btn-lg btn-primary col-12" type="submit">
                        Set
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
