import React, { useState, useEffect } from "react";
import { Modal, closeActiveModal } from "../../components/Modal";
import CalibersService from "../../services/CalibersService";

const CalibersStepView = (props) => {
  const [currentSubcategory, setCurrentSubcategory] = useState();
  const [selectedBulletTypes, setSelectedBulletTypes] = useState([]);
  const [activeBulletTypes, setActiveBulletTypes] = useState([]);
  const [calibers, setCalibers] = useState([]);

  useEffect(() => {}, [selectedBulletTypes, currentSubcategory, calibers]);

  useEffect(() => {
    const fetchCaliberTypes = async () => {
      const data = await CalibersService.getAllCalibers();
      setCalibers(data);
    };
    fetchCaliberTypes();
  }, []);

  const onClearAll = () => {
    const currentActiveBulletTypes = [...activeBulletTypes];
    var filtered = currentActiveBulletTypes.filter((csbt) => csbt.subcategoryId !== currentSubcategory.id);
    setActiveBulletTypes(filtered);
  };

  const onSet = () => {
    setSelectedBulletTypes(activeBulletTypes);
    closeActiveModal();
  };

  const onSelectedBulletType = (item) => {
    var currentActiveBulletTypes = [...activeBulletTypes];
    var index = currentActiveBulletTypes.findIndex((csbt) => csbt.subcategoryId === currentSubcategory.id && csbt.value === item);
    if (index !== -1) {
      currentActiveBulletTypes.splice(index, 1);
    } else {
      currentActiveBulletTypes = [...currentActiveBulletTypes, { categoryId: 99, subcategoryId: currentSubcategory.id, attributeId: currentSubcategory.attributeId, value: item }];
    }

    setActiveBulletTypes(currentActiveBulletTypes);
  };

  const getSelectedCalibers = (item) => {
    return selectedBulletTypes ? selectedBulletTypes.filter((sbt) => sbt.subcategoryId === item.id) : [];
  };

  const getActiveCalibersCount = (item) => {
    return activeBulletTypes ? activeBulletTypes.filter((sbt) => sbt.subcategoryId === item.id).length : 0;
  };

  const isBulletTypeChecked = (item) => {
    const indexActiveBulletTypes = activeBulletTypes && activeBulletTypes.findIndex((sbt) => sbt.subcategoryId === currentSubcategory.id && sbt.value === item);
    return indexActiveBulletTypes !== -1;
  };

  const openCalibersSelection = (subcategory) => {
    setCurrentSubcategory(subcategory);
  };

  return (
    <React.Fragment>
      <div className="row row-cols-1 g-2">
        {calibers &&
          calibers.map((item, i) => (
            <div className="col" key={i}>
              <label className="form-check-label w-100" htmlFor={`caliber-status-${i + 1}`}>
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="form-check">
                      <div className="row g-0 align-items-center">
                        <div className="col-auto">
                          <input
                            className="form-check-input"
                            id={`caliber-status-${i + 1}`}
                            type="checkbox"
                            name="caliber-status"
                            data-bs-toggle="modal"
                            data-bs-target="#select-calibers"
                            onClick={() => openCalibersSelection(item)}
                            checked={getSelectedCalibers(item).length > 0}
                            readOnly={true}
                          />
                        </div>
                        <div className="col ms-2">
                          <span className="d-block h6 mb-0">{item.name}</span>
                          <span className="d-block my-1 fs-7 lh-1 text-muted">
                            Selected {getSelectedCalibers(item).length} out of {item.values.length} bullet types.
                          </span>
                          {getSelectedCalibers(item).map((caliber, i) => (
                            <span key={i} className="badge text-uppercase text-secondary bg-secondary rounded-pill" style={{ "--bs-bg-opacity": "0.1" }}>
                              {caliber.value}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          ))}
      </div>
      <div className="nav row align-items-center mt-4">
        <div className="col">
          <button className="btn btn-lg btn-white" onClick={props.onBack} type="button">
            Back
          </button>
        </div>
        <div className="col">
          <button className="btn btn-lg btn-link" onClick={() => props.onSubmitStep2(selectedBulletTypes)}>
            Skip
          </button>
        </div>
        <div className="col-auto">
          <button className="btn btn-lg btn-primary" onClick={() => props.onSubmitStep2(selectedBulletTypes)}>
            Continue
          </button>
        </div>
      </div>

      <Modal id="select-calibers" size="modal-lg">
        <div>
          <div className="card-header border-0">
            <h5 className="card-header-title">
              {currentSubcategory && currentSubcategory.name && currentSubcategory.name} ({getActiveCalibersCount(currentSubcategory)} / {currentSubcategory && currentSubcategory.values.length})
            </h5>
            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="card-body pt-0" id="modal-html">
            <ul className="nav nav-pills nav-overflow mb-3">
              {calibers &&
                calibers.map((item, i) => (
                  <li key={i} className="nav-item">
                    <button
                      className={`nav-link d-flex align-items-center ${currentSubcategory && currentSubcategory.id === item.id ? "active" : ""}`}
                      id={`filter-${item.id}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#filter-${item.id}-tab-pane`}
                      role="tab"
                      aria-controls={`#filter-${item.id}-tab-pane`}
                      aria-selected="true"
                      onClick={() => setCurrentSubcategory(item)}
                    >
                      <img className="d-none d-md-inline-block lazy img-white me-2" src={item.imageUrl} alt="" height="14" />
                      <span>{item.name}</span>
                    </button>
                  </li>
                ))}
            </ul>
            <div className="tab-content" id="filter-tab-content">
              {calibers &&
                calibers.map((item, i) => (
                  <div
                    className={`tab-pane ${currentSubcategory && currentSubcategory.id === item.id ? "show active" : ""}`}
                    id={`filter-${item.id}-tab-pane`}
                    role="tabpanel"
                    aria-labelledby={`filter-${item.id}-tab`}
                    tabIndex="0"
                    key={i}
                  >
                    <div className="row row-cols-1 row-cols-lg-2 g-2">
                      {item.values.map((caliber, i) => (
                        <div className="col" key={i}>
                          <label className="form-check-label w-100" htmlFor={caliber}>
                            <div className="card card-xs mb-0">
                              <div className="card-body">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    id={caliber}
                                    type="checkbox"
                                    name="calibers"
                                    onClick={() => onSelectedBulletType(caliber)}
                                    checked={isBulletTypeChecked(caliber)}
                                    readOnly={true}
                                  />
                                  {caliber}
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="hstack gap-3 mt-3">
                      <button className="btn btn-lg btn-light w-100" type="clear" onClick={onClearAll}>
                        Clear all
                      </button>
                      <button className="btn btn-lg btn-primary w-100" onClick={onSet}>
                        Set
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CalibersStepView;
