import axios from "axios";

export const quotationEmail = async (order) => {
  return new Promise(async (resolve, reject) => {
    try {
      var loggedUserJson = localStorage.getItem("loggedUser");
      if (!loggedUserJson) {
        resolve(null);
      }

      var loggedUser = JSON.parse(loggedUserJson);
      const responseOrganizations = await fetch("/data/organizations.json");
      const organizations = await responseOrganizations.json();
      const organizationIndex = organizations.findIndex((o) => o.name === loggedUser.organization);
      const organization = organizations[organizationIndex];

      var requestBody = {
        quotationNumber: order.orderNumber,
        quotationDateTime: order.orderDate,
        quotationValidUntil: order.validUntilDate,
        organizationName: organization.name,
        organizationEmail: "string",
        organizationPhone: "string",
        organizationAddress: `${organization.zip}, ${organization.city}, ${organization.county}, ${organization.state}`,
        organizationPtt: organization.zip,
        organizationCity: organization.city,
        organizationState: organization.state,
        personFirstName: loggedUser.firstName,
        personLastName: loggedUser.lastName,
        personWorkPlace: loggedUser.organization,
        personEmail: loggedUser.email,
        personPhone: loggedUser.phone,
        shippingCost: 10,
        discountPercentage: 5,
        processingFeePercentage: 0,
        quotationItems: order.products
          ? order.products.map((p) => ({
              productSku: p.product.partNumber,
              productName: `${p.product.caliber} ${p.product.grain} ${p.product.bulletType}`,
              productDescription: p.product.brand,
              quantity: p.quantity,
              unitPrice: p.product.listPrice,
            }))
          : [],
      };

      axios
        .post("https://testapi-commerce.emaq.ba/api/Reports/QuoteEmail", requestBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch(() => {});
    } catch (error) {}
  });
};

export const purchaseOrderEmail = async (order) => {
  return new Promise(async (resolve, reject) => {
    try {
      var loggedUserJson = localStorage.getItem("loggedUser");
      if (!loggedUserJson) {
        resolve(null);
      }

      var loggedUser = JSON.parse(loggedUserJson);
      const responseOrganizations = await fetch("/data/organizations.json");
      const organizations = await responseOrganizations.json();
      const organizationIndex = organizations.findIndex((o) => o.name === loggedUser.organization);
      const organization = organizations[organizationIndex];

      var requestBody = {
        purchaseOrderNumber: order.orderNumber,
        purchaseOrderDateTime: order.orderDate,
        purchaseOrderValidUntil: order.validUntilDate,
        billToFirstName: loggedUser.firstName,
        billToLastName: loggedUser.lastName,
        billToPhone: loggedUser.phone,
        billToEmail: loggedUser.email,
        billToAddress: loggedUser.billingAddress,
        billToCity: organization.city,
        billToState: organization.state,
        shipToFirstName: loggedUser.firstName,
        shipToLastName: loggedUser.lastName,
        shipToPhone: loggedUser.phone,
        shipToEmail: loggedUser.email,
        shipToAddress: loggedUser.deliveryAddress,
        shipToCity: organization.city,
        shipToState: organization.state,
        workPlace: loggedUser.organization,
        carrierService: "UPS",
        deliveryDate: order.deliveryDate,
        shippingCost: 10,
        processingFeePercentage: 0,
        discountPercentage: 5,
        purchaseOrderItems: order.products
          ? order.products.map((p) => ({
              sku: p.product.partNumber,
              product: `${p.product.caliber} ${p.product.grain} ${p.product.bulletType}`,
              aboutProduct: p.product.brand,
              quantity: p.quantity,
              unitPrice: p.product.listPrice,
            }))
          : [],
      };

      axios
        .post("https://testapi-commerce.emaq.ba/api/Reports/PurchaseOrderEmail", requestBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  });
};

export const shippedEmail = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      var loggedUserJson = localStorage.getItem("loggedUser");
      if (!loggedUserJson) {
        resolve(null);
      }
      var loggedUser = JSON.parse(loggedUserJson);
      axios
        .post("https://testapi-commerce.emaq.ba/api/Reports/ShippedEmail", {
          emailTo: loggedUser.email,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch(() => {});
    } catch (error) {}
  });
};

const ReportsService = {
  quotationEmail,
  purchaseOrderEmail,
  shippedEmail,
};

export default ReportsService;
