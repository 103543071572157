import { useState, useEffect } from "react";
import ModalService from "./ModalService";

export default function ModalRoot() {
  const [modal, setModal] = useState({});

  useEffect(() => {
    ModalService.on("open", ({ component, props }) => {
      setModal({
        component,
        props,
        close: (value) => {
          setModal({});
        },
      });
    });
  }, []);

  const ModalComponent = modal.component ? modal.component : null;

  return (
    <>
      {ModalComponent && (
        <>
          <ModalComponent {...modal.props} close={modal.close} className={ModalComponent ? "d-block" : ""} />
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
}
