import axios from "axios";
import StoreService from "./StoreService";

export const getAllCalibers = () => {
  const storeId = StoreService.getStoreId();
  return new Promise((resolve, reject) => {
    try {
      axios
        .get("https://testapi-commerce.emaq.ba/api/Subcategories/GetCalibers?CategoryId=99&StoreId=" + storeId)
        .then((res) => {
          var response = res as any;
          resolve(response.data.resultList);
        })
        .catch(() => {});
    } catch (error) {}
  });
};

const CalibersService = {
  getAllCalibers,
};

export default CalibersService;
