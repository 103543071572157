export const ROOT = "/";
export const PROFILE = "/profile";
export const ORDERS = "/orders";
export const REPORTS = "/reports";
export const DOCUMENTS = "/documents";
export const SIGN_IN = "/sign-in";
export const SIGN_UP = "/sign-up";
export const CART = "/cart";
export const FORGOT_PASSWORD = "/forgot-password";
export const DASHBOARD = "/dashboard";
export const PROTECTED_PAGE = "/protected-page";
