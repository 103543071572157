import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import initChoices from "../../../utils/choices";
import React from "react";
import Flatpickr from "react-flatpickr";
import OrdersService from "../../../services/OrdersService";
import Select from "react-select";
import { useStore } from "../../../stores/StoreContext";
import { observer } from "mobx-react";

const OrderFiltersModal = observer(({
  onOrderStatusChange,
  onPaymentStatusChange,
  onOrderTypeChange,
  onPaymentTypeChange,
  onShippingStatusChange,
  onDateFromChange,
  onDateToChange,
  onSet,
  onClearAll,
  options,
}) => {
  const { filtersStore } = useStore();
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [paymentStatuses, setPaymentStatuses] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [shippingStatuses, setShippingStatuses] = useState([]);

  useEffect(() => {
    const fetchAndSetDropdowns = async () => {
      const orderStatuses = await OrdersService.getOrderStatuses();
      setOrderStatuses(orderStatuses);
      const paymentStatuses = await OrdersService.getPaymentStatuses();
      setPaymentStatuses(paymentStatuses);
      const orderTypes = await OrdersService.getOrderTypes();
      setOrderTypes(orderTypes);
      const paymentTypes = await OrdersService.getPaymentTypes();
      setPaymentTypes(paymentTypes);
      const shippingStatuses = await OrdersService.getShippingStatuses();
      setShippingStatuses(shippingStatuses);
    };
    fetchAndSetDropdowns();
    initChoices();
  }, []);

  return ReactDOM.createPortal(
    <div className="modal fade" id="set-product-filters" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div className="modal-content border-0">
          <div className="modal-header pb-0 border-0">
            <h5 className="modal-title lh-1">Set Filters</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="card-body pt-0">
              <form>
                <div className="mb-3">
                  <label className="form-label">Payment Status</label>
                  <Select
                    key={`payment-statuses-${paymentStatuses.length}-${filtersStore.orderFilters.paymentStatusId}`}
                    name={`payment-statuses`}
                    defaultValue={() => {
                      if (paymentStatuses && paymentStatuses.length > 0 && filtersStore.orderFilters.paymentStatusId) {
                        const selectedPaymentStatus = paymentStatuses.find((ps) => ps.paymentStatusId === filtersStore.orderFilters.paymentStatusId);
                        return selectedPaymentStatus ? { value: selectedPaymentStatus.paymentStatusId, label: selectedPaymentStatus.name } : null;
                      }
                      return null;
                    }}
                    options={
                      paymentStatuses &&
                      paymentStatuses.map((paymentStatus, i) => ({
                        value: paymentStatus.paymentStatusId,
                        label: paymentStatus.name,
                      }))
                    }
                    classNames={{ control: () => "form-control form-control-select" }}
                    onChange={onPaymentStatusChange}
                    isClearable
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Type</label>
                  <Select
                    key={`order-types-${orderTypes.length}-${filtersStore.orderFilters.orderTypeId}`}
                    name={`order-types`}
                    defaultValue={() => {
                      if (orderTypes && orderTypes.length > 0 && filtersStore.orderFilters.orderTypeId) {
                        const selectedOrderType = orderTypes.find((ot) => ot.orderTypeId === filtersStore.orderFilters.orderTypeId);
                        return selectedOrderType ? { value: selectedOrderType.orderTypeId, label: selectedOrderType.name } : null;
                      }
                      return null;
                    }}
                    options={
                      orderTypes &&
                      orderTypes.map((orderType, i) => ({
                        value: orderType.orderTypeId,
                        label: orderType.name,
                      }))
                    }
                    classNames={{ control: () => "form-control form-control-select" }}
                    onChange={onOrderTypeChange}
                    isClearable
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Payment Type</label>
                  <Select
                    key={`payment-types-${paymentTypes.length}-${filtersStore.orderFilters.paymentTypeId}`}
                    name={`payment-types`}
                    defaultValue={() => {
                      if (paymentTypes && paymentTypes.length > 0 && filtersStore.orderFilters.paymentTypeId) {
                        const selectedPaymentType = paymentTypes.find((pt) => pt.paymentTypeId === filtersStore.orderFilters.paymentTypeId);
                        return selectedPaymentType ? { value: selectedPaymentType.paymentTypeId, label: selectedPaymentType.name } : null;
                      }
                      return null;
                    }}
                    options={
                      paymentTypes &&
                      paymentTypes.map((paymentType, i) => ({
                        value: paymentType.paymentTypeId,
                        label: paymentType.name,
                      }))
                    }
                    classNames={{ control: () => "form-control form-control-select" }}
                    onChange={onPaymentTypeChange}
                    isClearable
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Shipping Status</label>
                  <Select
                    key={`shipping-statuses-${shippingStatuses.length}-${filtersStore.orderFilters.shippingStatusId}`}
                    name={`shipping-statuses`}
                    defaultValue={() => {
                      if (shippingStatuses && shippingStatuses.length > 0 && filtersStore.orderFilters.shippingStatusId) {
                        const selectedShippingStatus = shippingStatuses.find((ss) => ss.shippingStatusId === filtersStore.orderFilters.shippingStatusId);
                        return selectedShippingStatus ? { value: selectedShippingStatus.shippingStatusId, label: selectedShippingStatus.name } : null;
                      }
                      return null;
                    }}
                    options={
                      shippingStatuses &&
                      shippingStatuses.map((shippingStatus, i) => ({
                        value: shippingStatus.shippingStatusId,
                        label: shippingStatus.name,
                      }))
                    }
                    classNames={{ control: () => "form-control form-control-select" }}
                    onChange={onShippingStatusChange}
                    isClearable
                  />
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-6">
                    <div className="mb-2 mb-md-0">
                      <label className="form-label" htmlFor="order-date-from">
                        Order Date from
                      </label>
                      <div className="input-group input-group-merge input-group-reverse">
                        <Flatpickr
                          key={filtersStore.orderFilters.dateFrom}
                          className="form-control form-control-lg"
                          id="order-date-from"
                          placeholder="Order Date from"
                          type="text"
                          aria-label="Order Date from"
                          aria-describedby="order-date-from-calendar"
                          defaultValue={filtersStore && filtersStore.orderFilters.dateFrom}
                          onChange={onDateFromChange}
                        />
                        <div className="input-group-text" id="order-date-from-calendar">
                          <i className="fe fe-calendar"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-2 mb-md-0">
                      <label className="form-label" htmlFor="order-date-to">
                        Order Date to
                      </label>
                      <div className="input-group input-group-merge input-group-reverse">
                        <Flatpickr
                          key={filtersStore.orderFilters.dateTo}
                          className="form-control form-control-lg"
                          id="order-date-to"
                          placeholder="Order Date to"
                          type="text"
                          aria-label="Order Date to"
                          aria-describedby="order-date-to-calendar"
                          defaultValue={filtersStore && filtersStore.orderFilters.dateTo}
                          onChange={onDateToChange}
                        />
                        <div className="input-group-text" id="order-date-to-calendar">
                          <i className="fe fe-calendar"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hstack gap-3 mt-3">
                  <button className="btn btn-lg btn-light w-100" type="reset" onClick={onClearAll}>
                    Clear all
                  </button>
                  <button className="btn btn-lg btn-primary w-100" type="BUTTON" onClick={onSet}>
                    Set
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("order-filters-modal")
  );
});

export { OrderFiltersModal };
