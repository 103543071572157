import React, { useEffect, useState } from "react";
import { useStore } from "../../stores/StoreContext";
import { observer } from "mobx-react";
import "react-toastify/dist/ReactToastify.css";
import { formatPrice } from "../../utils/formatters";
import UserService from "../../services/UserService";
import debounce from "debounce";
import { ToastContainer, toast } from "react-toastify";
import OrdersService from "../../services/OrdersService";
import BuyNowModal from "../../modals/BuyNowModal";
import ModalService from "../_Layout/Modal/ModalService";
import PurchaseOrderModal from "../../modals/PurchaseOrderModal";
import QuoteOrderModal from "../../modals/QuoteOrderModal";
import { toggleCart } from "../../utils/cart";

export const CartView = observer(() => {
  const { cartStore } = useStore();
  const [currentUser, setCurrentUser] = useState();
  const [isLoadingQuoteOrder, setIsLoadingQuoteOrder] = useState(false);
  const [isCurrentUserIndividual, setIsCurrentUserIndividual] = useState();

  const percentageRatio = (cartStore.totalPrice / cartStore.budget) * 100;

  const removeFromCart = (id, packageId, quantity = 1) => {
    cartStore.removeProduct(id, packageId, quantity);
  };

  const itemCartQuantity = (id, packageId) => {
    return cartStore.getQuantity(id, packageId);
  };

  const isBudgetReached = (price) => {
    return cartStore.isEnteredBudgetLimit && cartStore.budget && cartStore.totalPrice + price >= cartStore.budget;
  };

  const addToCart = (id, productPackage, quantity = 1, overwrite = false) => {
    if (isNaN(quantity))
      return;

    if (quantity == 0) {
      cartStore.removeProduct(id, productPackage.id, quantity);
    } else {
      cartStore.addProduct({ id: id }, productPackage, quantity, overwrite);
    }
  };

  const onQuoteOrder = async () => {
    const totalPrice = cartStore.totalPrice;

    setIsLoadingQuoteOrder(true);
    const data = await OrdersService.quoteOrder(cartStore.products);
    setIsLoadingQuoteOrder(false);
    if (data.id) {
      ModalService.open(QuoteOrderModal, {
        quoteOrderNumber: data.orderNumber,
        quoteOrderDocuments: data.documents,
        onPurchaseOrder: () => {
          ModalService.open(PurchaseOrderModal, { orderId: data.id, totalPrice });
        },
      });
      toggleCart();
      cartStore.clearCart();
    }
  };

  const onClearAll = () => {
    cartStore.clearCart();
    toast.success("Cart is successfully cleared!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    const currentUser = UserService.getCurrentUser();
    setCurrentUser(currentUser);
    const relationship = UserService.getCurrentUserRelationship();
    setIsCurrentUserIndividual(relationship === "Self");
  }, []);

  const openBuyNowModal = () => {
    ModalService.open(BuyNowModal);
  };

  const openPurchaseOrderModal = () => {
    ModalService.open(PurchaseOrderModal);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="container mt-3">
        <div className="card">
          <div className="card-header">
            <h5 className="card-header-title">Budget</h5>
            <a className="btn btn-white" data-bs-toggle="modal" data-bs-target="#budget-modal" href="#!">
              <span className="fe fe-budget"></span>
              &nbsp; <span>Set budget</span>
            </a>
          </div>
          {cartStore.budget && (
            <div className="card-body">
              <span className="d-flex">
                <span>
                  <span className="d-block lh-1 fs-8 fw-normal text-muted">Total</span>
                  <span className="lh-1 fs-7 fw-bold text-primary">{formatPrice(cartStore.totalPrice)}</span>
                </span>
                <span className="ms-auto text-end budget-remaining">
                  <span className={`d-block lh-1 fs-8 ${cartStore.remainingBudget < 0 ? "" : "text-nobel"}`}>{cartStore.remainingBudget < 0 ? "out of budget " : "remaining "}</span>
                  <span className="lh-1 fs-7 fw-bold">
                    {" "}
                    {cartStore.remainingBudget < 0 && <>{formatPrice(Math.abs(cartStore.remainingBudget))}</>}
                    {cartStore.remainingBudget >= 0 && formatPrice(cartStore.remainingBudget)}
                  </span>
                </span>
              </span>
              <span className="budget-progress">
                <span className="progress progress-sm">
                  <span
                    className={`progress-bar ${percentageRatio < 60 ? "bg-success" : percentageRatio < 90 ? "bg-warning" : "bg-danger"}`}
                    role="progressbar"
                    style={{ width: `${percentageRatio}%` }}
                    aria-valuenow={percentageRatio}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></span>
                </span>
              </span>
            </div>
          )}
        </div>
        <div className="card">
          <div className="card-header">
            <h5 className="card-header-title">Cart ({cartStore.totalItems})</h5>
            <p className="mb-0 text-end">
              <span className="d-block lh-1 fs-8 fw-normal text-muted">Subtotal</span>
              <span className="lh-1 fs-7 fw-bold text-primary">{formatPrice(cartStore.totalPrice)}</span>
            </p>
          </div>
          <div className="card-body">
            <div className="list-group list-group-flush">
              {!cartStore.products || (cartStore.products.length === 0 && <span className="fw-normal text-muted">Add product(s) to cart to be shown here. </span>)}
              {cartStore.products &&
                cartStore.products.map((item, i) => {
                  let prefix = item.package?.prefix ?? item.product?.package?.prefix ?? null;
                  let suffix = item.package?.suffix ?? item.product?.package?.suffix ?? null;

                  return (
                    <div key={i} className="list-group-item" href="dashboard-product.html">
                      <p className="mb-1 fs-8 text-muted">
                        {prefix ? (
                          <>
                            <strong>{prefix}</strong> |{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {item.product.sku}
                        {suffix ? (
                          <>
                            {" "}
                            | <strong>{suffix}</strong>
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                      <h6 className="mb-1 fs-7">
                        {" "}
                        {item.product.manufacturer} {item.product.primaryAttributes[0].value} &middot; {item.product.primaryAttributes[1].value} &middot; {item.product.primaryAttributes[2].value}
                      </h6>
                      <div className="row g-0 align-items-center">
                        <div className="col">
                          <div className="btn-number d-inline-flex text-dark bg-light rounded-pill">
                            <button className="btn btn-sm btn-link btn-minus px-1" onClick={() => removeFromCart(item.product.id, item.package.id)}>
                              <span className="fe fe-minus align-middle"></span>
                            </button>
                            <input
                              key={`cart-${item.product.id}-${itemCartQuantity(item.product.id, item.package.id)}`}
                              className={`form-control form-control-sm form-control-flush  px-0 text-center rounded-0 id-${item.product.id}-${item.package.id}`}
                              type="text"
                              name="quantity"
                              defaultValue={itemCartQuantity(item.product.id, item.package.id)}
                              disabled={isBudgetReached(item.package.price)}
                              onFocus={(e) => e.target.select()}
                              onBlur={(e) => {
                                if (!e.target.value || !e.target.value.length || isNaN(parseInt(e.target.value)))
                                  removeFromCart(item.product.id, item.package.id);
                              }}
                              onChange={debounce(async (e) => {
                                addToCart(item.product.id, item.package, parseInt(e.target.value), true);
                              }, 500)}
                              min={0}
                            />
                            <button className="btn btn-sm btn-link btn-plus px-1" onClick={() => addToCart(item.product.id, item.package)} disabled={isBudgetReached(item.package.price)}>
                              <span className="fe fe-plus align-middle"></span>
                            </button>
                          </div>
                        </div>
                        <div className="col-3 col-md-2 text-end">
                          <p className="mb-0 fs-7 text-muted">
                            {" "}
                            {item.package.unitQuantity * itemCartQuantity(item.product.id, item.package.id)} {item.product.measurementUnit}
                          </p>
                          <p className="mb-0 fs-6 fw-bold">{formatPrice(item.package.price * item.quantity)}</p>
                        </div>
                        <div className="col-auto me-n2">
                          <button className="btn btn-link text-danger" onClick={() => removeFromCart(item.product.id, item.package.id, 0)}>
                            <i className="fe fe-x"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="card-footer">
            <div className="row row-cols-2 row-cols-xxl-4 g-2">
              {!isCurrentUserIndividual && <>
                <button
                  className="btn btn-light btn-sm  btn-primary-hover text-white-hover border-none px-2 py-1 fs-6"
                  onClick={onQuoteOrder}
                  disabled={cartStore.products.length === 0 || isLoadingQuoteOrder}
                >
                  {isLoadingQuoteOrder && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                  &nbsp;
                  <span className="fe fe-file-text"></span>
                  &nbsp; <span>Create Quote</span>
                </button>

                <div className="col">
                  <button className="btn btn-success text-white w-100" href="#!" onClick={openPurchaseOrderModal} disabled={cartStore.products.length === 0}>
                    <span>Submit PO</span>
                  </button>
                </div>
              </>}

              <div className="col">
                <button className="btn btn-warning w-100" onClick={openBuyNowModal} disabled={cartStore.products.length === 0}>
                  <span className="fe fe-credit-card"></span>
                  &nbsp; <span>Buy Now</span>
                </button>
              </div>

              <div className="col ms-auto">
                <button className="btn btn-light w-100" href="#!" disabled={cartStore.products.length === 0} onClick={onClearAll}>
                  Clear all
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
