import React, { useState, useEffect } from "react";
import ProfileStepView from "./ProfileStepView";
import CalibersStepView from "./CalibersStepView";
import ContactStepView from "./ContactStepView";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import { useNavigate, useSearchParams } from "react-router-dom";
import VerificationStepView from "./VerificationStepView";
import UserService from "../../services/UserService";
import { convertToBase64 } from "../../utils/base64";

export function SignUpContainer() {
  let auth = useAuth();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState();
  const [IDmeData, setIDmeData] = useState();
  const [isIDmeLoading, setIsIDmeLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [errors, setErrors] = useState();

  useEffect(() => {
    const code = searchParams.get("code");
    if (!code) {
      navigate("/sign-in", { state: { unauthorized: true } });
    }
    const checkUser = async () => {
      setIsIDmeLoading(true);
      var data = await UserService.check(code);
      setIsIDmeLoading(false);
      if (!data || data.resultList || (data.resultList && data.resultList.length > 0) || (data.status && data.status.length > 0 && !data.status[0].verified)) {
        navigate("/sign-in", { state: { unauthorized: true, errorMessage: data.resultList && data.resultList.length > 0 && data.resultList[0].description } });
      }
      setIDmeData(data);
    };
    checkUser();
  }, []);

  useEffect(() => {}, [step, formData, auth, navigate]);

  const onSubmitStep1 = async (data) => {
    if (data.taxExemptionFile && data.taxExemptionFile.length > 0) {
      data.isBusinessEntityTaxExemptFile = await convertToBase64(data.taxExemptionFile[0]);
    }
    delete data.fullName;
    setFormData(data);
    setStep(step + 1);
  };

  const onSubmitStep2 = (data) => {
    setFormData({ ...formData, userInterests: data });
    setStep(step + 1);
  };

  const onSubmitStep3 = (data) => {
    setFormData({ ...formData, ...data });
    const signUp = async () => {
      setIsLoading(true);
      var response = await UserService.signUp({ ...formData, ...data });
      setIsLoading(false);
      if (!response.hasErrors) {
        navigate("/sign-in", {
          state: { firstName: formData.firstName, lastName: formData.lastName },
        });
      } else {
        setErrors(response.resultList);
      }
    };
    signUp();
  };

  const onBack = () => {
    setStep(step - 1);
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-center min-vh-100 bg-pattern">
        <div className="container">
          <div className="row justify-content-center">
            <div className={`${step >= 1 && step <= 3 ? "col-12 col-md-7 col-xxl-8" : "col-12 col-md-4"}`}>
              <div className="my-4 text-center">
                <img className="d-inline-block logo logo-lg" src="./assets/img/brand/logo.png" alt="" />
              </div>

              {isIDmeLoading && (
                <div className="row justify-content-center mt-5 mb-5">
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      "--bs-spinner-border-width": "0.15em",
                    }}
                  ></div>
                </div>
              )}

              {!isIDmeLoading && (
                <div className="card card-lg">
                  <div className="card-body">
                    {step >= 1 && step <= 3 && (
                      <React.Fragment>
                        <div className="list-group list-group-flush list-group-horizontal list-group-steps mx-xl-5 mb-4 text-center">
                          <div className={`list-group-item ${step === 1 ? "current" : step > 1 ? "success" : ""}`}>
                            <div className="box box-sm mb-2">
                              <div className="box-title shape fs-5 border border-2 rounded-circle">1</div>
                            </div>
                            <p className="mb-0">Profile</p>
                          </div>
                          <div className={`list-group-item ${step === 2 ? "current" : step > 2 ? "success" : ""}`}>
                            <div className="box box-sm mb-2">
                              <div className="box-title shape fs-5 border border-2 rounded-circle">2</div>
                            </div>
                            <p className="mb-0">Calibers</p>
                          </div>
                          <div className={`list-group-item ${step === 3 ? "current" : step > 3 ? "success" : ""}`}>
                            <div className="box box-sm mb-2">
                              <div className="box-title shape fs-5 border border-2 rounded-circle">3</div>
                            </div>
                            <p className="mb-0">Account</p>
                          </div>
                        </div>

                        {!isIDmeLoading && (
                          <div style={{ display: step === 1 ? "block" : "none" }}>
                            <ProfileStepView onSubmitStep1={onSubmitStep1} IDmeData={IDmeData} />
                          </div>
                        )}

                        {!isIDmeLoading && (
                          <div style={{ display: step === 2 ? "block" : "none" }}>
                            <CalibersStepView onSubmitStep2={onSubmitStep2} onBack={onBack} IDmeData={IDmeData} />{" "}
                          </div>
                        )}

                        {!isIDmeLoading && (
                          <div style={{ display: step === 3 ? "block" : "none" }}>
                            <ContactStepView onSubmitStep3={onSubmitStep3} onBack={onBack} IDmeData={IDmeData} formData={formData} isLoading={isLoading} errors={errors} />{" "}
                          </div>
                        )}
                      </React.Fragment>
                    )}
                    <div style={{ display: step === 4 ? "block" : "none" }}>
                      <VerificationStepView onBack={onBack} />{" "}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
