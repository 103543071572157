import Modal from "../pages/_Layout/Modal/Modal";
import ModalBody from "../pages/_Layout/Modal/components/ModalBody";
import ModalHeader from "../pages/_Layout/Modal/components/ModalHeader";
import ModalFooter from "../pages/_Layout/Modal/components/ModalFooter";
import { useForm } from "react-hook-form";
import OrdersService from "../services/OrdersService";
import { toggleCart } from "../utils/cart";
import { useStore } from "../stores/StoreContext";
import { toast } from "react-toastify";
import { usePaymentInputs } from "react-payment-inputs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useState, useEffect, useRef } from "react";
import images from "react-payment-inputs/images";
import { handleValidateCardExpiryDate } from "../utils/regex";
import BillingShippingInfoForm from "../forms/BillingShippingInfoForm";
import { formatPrice } from "../utils/formatters";
import PasswordInput from "../components/PasswordInput";

export default function BuyNowModal(props) {
  const { cartStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [buyNowStep, setBuyNowStep] = useState(1);
  const [billingShippingInfo, setBillingShippingInfo] = useState();
  const billingShippingInfoFormRef = useRef();
  const [isBillingShippingInfoValid, setIsBillingShippingInfoValid] = useState(false);

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchPaymentTerm = watch("paymentTerm");
  const [paymentType, setPaymentType] = useState(3);
  const { getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();

  useEffect(() => {
    if (paymentType === 3) {
      register("cardNumber", {
        required: "Card number is required.",
        validate: (value) => {
          if (value) {
            const cleanedValue = value.replace(/\s/g, "").trim();
            if (cleanedValue.length < 13) {
              return "Min. length of card number is 13";
            }
            if (cleanedValue.length > 16) {
              return "Max. length of card number is 16";
            }
          }
        },
      });
      register("expiryDate", {
        required: "Expiry date is required.",
        validate: (value) => {
          if (value) {
            if (handleValidateCardExpiryDate(value)) {
              const month = +value.split("/")[0];
              const year = +value.split("/")[1];
              const currentYear = +new Date().getFullYear().toString().substring(2);
              const currentMonth = new Date().getMonth() + 1;
              if (year < currentYear || (year === currentYear && month < currentMonth)) {
                return "Expiry date is invalid.";
              }
            } else {
              return "Expiry date must be in MM/YY format.";
            }
          }
        },
      });
      register("CVV", {
        required: "CVV/CVC is required.",
        minLength: {
          value: 3,
          message: "Min. length of CVV/CVC is 3.",
        },
        maxLength: {
          value: 4,
          message: "Max. length of CVV/CVC is 4.",
        },
      });
    } else if (paymentType === 5) {
      unregister("cardNumber");
      unregister("expiryDate");
      unregister("CVV");
    }
  }, [paymentType]);

  const onBuyNow = async (data) => {
    setIsLoading(true);

    const response = await OrdersService.buyNowOrder(
      props.orderId,
      props.orderId ? null : cartStore.products,
      paymentType,
      paymentType === 3 ? {
        cardNumber: data.cardNumber.replaceAll(" ", ""),
        cardCode: data.CVV,
        expirationYear: 2000 + (+data.expiryDate.split("/")[1]),
        expirationMonth: +data.expiryDate.split("/")[0],
      } : null,
      paymentType === 5 ? {
        routingNumber: data.routingNumber.replaceAll(" ", "").replaceAll("-", ""),
        accountNumber: data.accountNumber,
        nameOnAccount: data.bankAccountName,
        bankName: data.bankName,
        checkNumber: data.checkNumber
      } : null,
      {
        billFirstName: billingShippingInfo.billingFirstName,
        billLastName: billingShippingInfo.billingLastName,
        billCompanyName: billingShippingInfo.billingCompanyName,
        billPhoneNumber: billingShippingInfo.billingPhoneNumber,
        billEmailAddress: billingShippingInfo.billingEmail,
        billRegionName: billingShippingInfo.billingState,
        billRegionCode: billingShippingInfo.shippingState,
        billCityName: billingShippingInfo.billingCity,
        billPostalCode: billingShippingInfo.billingZip,
        billAddress: billingShippingInfo.billingAddress,
        billCountryName: "United States of America",
        billCountryCode: "USA",

        shipFirstName: billingShippingInfo.shippingFirstName,
        shipLastName: billingShippingInfo.shippingLastName,
        shipCompanyName: billingShippingInfo.shippingCompanyName,
        shipPhoneNumber: billingShippingInfo.shippingPhoneNumber,
        shipEmailAddress: billingShippingInfo.shippingEmail,
        shipRegionName: billingShippingInfo.shippingState,
        shipRegionCode: billingShippingInfo.shippingState,
        shipCityName: billingShippingInfo.shippingCity,
        shipPostalCode: billingShippingInfo.shippingZip,
        shipAddress: billingShippingInfo.shippingAddress,
        shipCountryName: "United States of America",
        shipCountryCode: "USA",
      }
    );

    if (response.id) {
      if (!props.orderId) {
        toggleCart();
        cartStore.clearCart();
      }
      props.close();

      toast.success("Payment is successfully completed!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.resultList && response.resultList.length > 0) {
      if (paymentType === 3) {
        const cardNumberError = response.resultList.find((rl) => rl.key === "PaymentRequest.CreditCardInfo.CardNumber");
        const expirationYearError = response.resultList.find((rl) => rl.key === "PaymentRequest.CreditCardInfo.ExpirationYear");
        const expirationMonthError = response.resultList.find((rl) => rl.key === "PaymentRequest.CreditCardInfo.ExpirationMonth");
        const cardCodeError = response.resultList.find((rl) => rl.key === "PaymentRequest.CreditCardInfo.CardCode");

        if (cardNumberError) {
          setError("cardNumber", { message: cardNumberError.description });
        }

        if (expirationYearError || expirationMonthError) {
          setError("expiryDate", {
            message: expirationYearError ? expirationYearError.description : expirationMonthError.description,
          });
        }

        if (cardCodeError) {
          setError("CVV", { message: cardCodeError.description });
        }
      } else if (paymentType === 5) {
        const routingNumberError = response.resultList.find((rl) => rl.key === "PaymentRequest.BankCheckInfo.RoutingNumber");
        const accountNumberError = response.resultList.find((rl) => rl.key === "PaymentRequest.BankCheckInfo.AccountNumber");
        const bankNameError = response.resultList.find((rl) => rl.key === "PaymentRequest.BankCheckInfo.BankName");
        const bankAccountNameError = response.resultList.find((rl) => rl.key === "PaymentRequest.BankCheckInfo.BankAccountName");

        if (routingNumberError) {
          setError("routingNumber", { message: routingNumberError.description });
        }

        if (accountNumberError) {
          setError("accountNumber", { message: accountNumberError.description });
        }

        if (bankNameError) {
          setError("bankName", { message: bankNameError.description });
        }

        if (bankAccountNameError) {
          setError("bankAccountName", { message: bankAccountNameError.description });
        }
      }
    }
    setIsLoading(false);
  };

  const onCardNumberChange = (e) => {
    e.target.value = e.target.value
      .replace(/\s/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
    setValue("cardNumber", e.target.value, { shouldValidate: true });
  };

  const onExpiryDateChange = (e) => {
    setValue("expiryDate", e.target.value, { shouldValidate: true });
  };

  const onCVVChange = (e) => {
    setValue("CVV", e.target.value, { shouldValidate: true });
  };

  const onBillingShippingInfo = async (data) => {
    setBillingShippingInfo(data);
    setBuyNowStep(2);
  };

  const onBuyNowNextStep = () => {
    if (buyNowStep === 1) {
      handleBillingShippingInfoSubmit();
    } else if (buyNowStep === 2) {
      handleSubmit(onBuyNow)();
    }
  };

  const handleBillingShippingInfoSubmit = () => {
    billingShippingInfoFormRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  return (
    <Modal size="modal-lg">
      <ModalHeader className="pb-3 border-bottom">
        <h5 className="modal-title lh-1">Buy Now</h5>
        <button type="button" className="btn-close" onClick={props.close} aria-label="Close"></button>
      </ModalHeader>
      <ModalBody className="py-0 px-0">
        <div className="card mb-0 border-0 shadow-none">
          <div className="card-body p-0">
            <div className="mb-0">
              <ul className="stepper stepper-vertical">
                <li className={`stepper-step p-0 ${buyNowStep === 1 ? "opened marked" : ""} ${buyNowStep > 1 ? "completed" : ""}`}>
                  <div className="stepper-head border-bottom bg-light px-3 py-2">
                    <span className="stepper-head-icon">1</span>
                    <span className="stepper-head-text">
                      <span className="d-block">
                        <span className="d-block">Location</span>
                        <span className="d-block fw-normal text-muted fs-7">
                          {!billingShippingInfo && "Please enter your billing and shipping info"}
                          {billingShippingInfo && `${billingShippingInfo.billingFirstName} ${billingShippingInfo.billingLastName}, ${billingShippingInfo.billingAddress}, ${billingShippingInfo.billingState}, ${billingShippingInfo.billingCity}, ${billingShippingInfo.shippingZip}`}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div className={`stepper-content ${buyNowStep === 1 ? "p-3" : "p-0"}`}>
                    <BillingShippingInfoForm
                      onValidChange={(isValid) => {
                        if (isBillingShippingInfoValid != isValid) {
                          setIsBillingShippingInfoValid(isValid);
                        }
                      }}
                      onSubmit={onBillingShippingInfo}
                      formRef={billingShippingInfoFormRef}
                    />
                  </div>
                </li>
                <li className={`stepper-step p-0 ${buyNowStep === 2 ? "opened marked" : ""} ${buyNowStep > 2 ? "completed" : ""}`}>
                  <div className="stepper-head bg-light px-3 py-2">
                    <span className={`stepper-head-icon ${buyNowStep < 2 ? "border border-primary" : ""}`}>2</span>
                    <span className="stepper-head-text">
                      <span className="d-block">
                        <span className="d-block">Payment</span>
                        <span className="d-block fw-normal text-muted fs-7">Please enter your payment info</span>
                      </span>
                    </span>
                  </div>
                  <div className={`stepper-content ${buyNowStep === 2 ? "p-3" : "p-0"}`}>
                    <form>
                      <div className="card mb-0 border-0 shadow-none">
                        <div className="card-body p-0">
                          <div className="accordion accordion-flush" id="buy-now-payment-method">
                            <div className="accordion-item text-body border-0" onClick={() => setPaymentType(3)}>
                              <label className="accordion-button accordion-button-radio px-0 border-0 shadow-none" htmlFor="buy-now-payment-credit-card-method" id="buy-now-payment-method-credit-card">
                                <span className="col">
                                  <img className="col-1" src="./assets/img/payment/credit-card.svg" alt="" height="14" />
                                  <span>Credit card</span>
                                </span>
                                <span className="col-auto">
                                  <input
                                    className="form-check-input"
                                    id="buy-now-payment-credit-card-method"
                                    type="radio"
                                    name="buy-now-payment-method"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#buy-now-payment-credit-card"
                                    {...register("paymentTerm")}
                                    disabled={isLoading}
                                  />
                                </span>
                              </label>
                              <div
                                id="buy-now-payment-credit-card"
                                className="accordion-collapse collapse"
                                aria-labelledby="buy-now-payment-method-credit-card"
                                data-bs-parent="#buy-now-payment-method"
                              >
                                <div className="accordion-body p-2 pe-0">
                                  <div className="card card-sm mb-0">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-12 mb-3">
                                          <label className="form-label" htmlFor="buy-now-card-number">
                                            Credit Card Number
                                          </label>
                                          <div className="input-group input-group-merge input-group-reverse">
                                            <input
                                              {...getCardNumberProps({ onChange: onCardNumberChange })}
                                              id="buy-now-card-number"
                                              className={`form-control form-control-lg ${errors.cardNumber ? "border-danger" : ""}`}
                                              placeholder="1234 5678 9101 1121"
                                              type="text"
                                              aria-describedby="buy-now-card-number-credit"
                                              minLength={13}
                                              maxLength={19}
                                              disabled={isLoading}
                                            />
                                            <div id="buy-now-card-number-credit" className={`input-group-text ${errors.cardNumber ? "border-danger" : ""}`}>
                                              <svg {...getCardImageProps({ images })} />
                                            </div>
                                          </div>

                                          {errors.cardNumber && <small className="text-danger">{errors.cardNumber.message}</small>}
                                        </div>
                                        <div className="col-6">
                                          <label className="form-label" htmlFor="buy-now-card-expiry">
                                            Expiry Date
                                          </label>
                                          <div className="input-group input-group-merge input-group-reverse">
                                            <input
                                              {...getExpiryDateProps({ onChange: onExpiryDateChange })}
                                              className={`form-control form-control-lg ${errors.expiryDate ? "border-danger" : ""}`}
                                              id="buy-now-card-expiry"
                                              placeholder="MM/YY"
                                              type="text"
                                              aria-describedby="buy-now-card-expiry-calendar"
                                              maxLength={7}
                                              disabled={isLoading}
                                            />
                                            <div id="buy-now-card-expiry-calendar" className={`input-group-text ${errors.expiryDate ? "border-danger" : ""}`}>
                                              <i className="fe fe-calendar"></i>
                                            </div>
                                          </div>
                                          {errors.expiryDate && <small className="text-danger">{errors.expiryDate.message}</small>}
                                        </div>
                                        <div className="col-6">
                                          <label className="form-label" htmlFor="buy-now-card-code">
                                            Security Code
                                          </label>
                                          <PasswordInput
                                            id="buy-now-card-code"
                                            placeholder="CVV"
                                            minLength={3}
                                            maxLength={4}
                                            disabled={isLoading}
                                            inputGroupClassName={errors.CVV ? "" : "mb-3"}
                                            iconClassName={errors.CVV && "border-danger text-danger"}
                                            inputClassName={errors.CVV && "border-danger"}
                                            formOptions={getCVCProps({ onChange: onCVVChange, type: null })}
                                          />
                                          {errors.CVV && <small className="text-danger">{errors.CVV.message}</small>}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item text-body border-0" onClick={() => setPaymentType(5)}>
                              <label className="accordion-button accordion-button-radio px-0 border-0 shadow-none" htmlFor="buy-now-payment-check-method" id="buy-now-payment-method-check">
                                <span className="col">
                                  <img className="col-1" src="./assets/img/payment/check.svg" alt="" height="14" />
                                  <span>Check</span>
                                </span>
                                <span className="col-auto">
                                  <input
                                    className="form-check-input"
                                    id="buy-now-payment-check-method"
                                    type="radio"
                                    name="buy-now-payment-method"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#buy-now-payment-check"
                                    {...register("paymentTerm")}
                                    disabled={isLoading}
                                  />
                                </span>
                              </label>
                              <div id="buy-now-payment-check" className="accordion-collapse collapse" aria-labelledby="buy-now-payment-method-check" data-bs-parent="#buy-now-payment-method">
                                <div className="accordion-body p-2 pe-0">
                                  <div className="card card-sm mb-0">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-6 mb-3">
                                          <label className="form-label w-100" htmlFor="buy-now-routing-number">
                                            Routing Number <span className="text-danger">*</span>
                                            <OverlayTrigger
                                              placement="top"
                                              trigger="click"
                                              overlay={
                                                <Tooltip>
                                                  The ABA Routing Number is a nine-digit number that identifies the financial institution associated with a customer’s bank account. To obtain a
                                                  routing number, look at the bottom left corner of a current check or deposit slip from the bank.
                                                </Tooltip>
                                              }
                                              rootClose={true}
                                            >
                                              <a className="float-end text-info" href="#!">
                                                <span className="fe fe-info"></span>
                                              </a>
                                            </OverlayTrigger>
                                          </label>
                                          <div className="input-group input-group-merge input-group-reverse">
                                            <input
                                              className={`form-control form-control-lg ${errors.routingNumber ? "border-danger" : ""}`}
                                              id="buy-now-routing-number"
                                              placeholder=""
                                              type="text"
                                              aria-describedby="routing-number-check"
                                              {...(paymentType === 5 && register("routingNumber", {
                                                required: true,
                                                maxLength: 9
                                              }))}
                                              disabled={isLoading}
                                            />
                                            <div id="buy-now-routing-number-check" className={`input-group-text ${errors.routingNumber ? "border-danger" : ""}`}>
                                              <i className="fe fe-hash"></i>
                                            </div>
                                          </div>
                                          {errors.routingNumber && errors.routingNumber.type === "required" && (
                                            <small className="text-danger">Routing number is required.</small>
                                          )}
                                          {errors.routingNumber && errors.routingNumber.type !== "required" && (
                                            <small className="text-danger">Routing number not in valid format.</small>
                                          )}
                                        </div>
                                        <div className="col-6 mb-3">
                                          <label className="form-label w-100" htmlFor="buy-now-account-number">
                                            Account Number <span className="text-danger">*</span>
                                            <OverlayTrigger
                                              placement="top"
                                              trigger="click"
                                              overlay={
                                                <Tooltip>
                                                  The account number associated with a savings or checking account at a financial institution. The checking account number is located at the bottom
                                                  of a check.
                                                </Tooltip>
                                              }
                                              rootClose={true}
                                            >
                                              <a className="float-end text-info" href="#!">
                                                <span className="fe fe-info"></span>
                                              </a>
                                            </OverlayTrigger>
                                          </label>
                                          <div className="input-group input-group-merge input-group-reverse">
                                            <input
                                              className={`form-control form-control-lg ${errors.accountNumber ? "border-danger" : ""}`}
                                              id="buy-now-account-number"
                                              placeholder=""
                                              type="text"
                                              aria-describedby="account-number-check"
                                              {...(paymentType === 5 && register("accountNumber", {
                                                required: true,
                                                maxLength: 17
                                              }))}
                                              disabled={isLoading}
                                            />
                                            <div id="buy-now-account-number-check" className={`input-group-text ${errors.accountNumber ? "border-danger" : ""}`}>
                                              <i className="fe fe-hash"></i>
                                            </div>
                                          </div>
                                          {errors.accountNumber && errors.accountNumber.type === "required" && (
                                            <small className="text-danger">Account Number is required.</small>
                                          )}
                                          {errors.accountNumber && errors.accountNumber.type !== "required" && (
                                            <small className="text-danger">Account Number not in valid format.</small>
                                          )}
                                        </div>
                                        <div className="col-12 mb-3">
                                          <label className="form-label" htmlFor="buy-now-bank-name">
                                            Bank Name
                                          </label>
                                          <div className="input-group input-group-merge input-group-reverse">
                                            <input
                                              className={`form-control form-control-lg ${errors.bankName ? "border-danger" : ""}`}
                                              id="buy-now-bank-name"
                                              placeholder=""
                                              type="text"
                                              aria-describedby="bank-name-check"
                                              {...(paymentType === 5 && register("bankName", {
                                                required: false,
                                                maxLength: 22
                                              }))}
                                              disabled={isLoading}
                                            />
                                            <div id="buy-now-bank-name-check" className={`input-group-text ${errors.bankName ? "border-danger" : ""}`}>
                                              <i className="fe fe-home"></i>
                                            </div>
                                          </div>
                                          {errors.bankName && errors.bankName.type === "required" && (
                                            <small className="text-danger">Bank Name is required.</small>
                                          )}
                                          {errors.bankName && errors.bankName.type !== "required" && (
                                            <small className="text-danger">Bank Name not in valid format.</small>
                                          )}
                                        </div>
                                        <div className="col-12 mb-3">
                                          <label className="form-label" htmlFor="buy-now-bank-account-name">
                                            Bank Account Name <span className="text-danger">*</span>
                                          </label>
                                          <div className="input-group input-group-merge input-group-reverse">
                                            <input
                                              className={`form-control form-control-lg ${errors.bankAccountName ? "border-danger" : ""}`}
                                              id="buy-now-bank-account-name"
                                              placeholder=""
                                              type="text"
                                              aria-describedby="buy-now-bank-account-name-check"
                                              {...(paymentType === 5 && register("bankAccountName", {
                                                required: true,
                                                maxLength: 22
                                              }))}
                                              disabled={isLoading}
                                            />
                                            <div id="buy-now-bank-account-name-check" className={`input-group-text ${errors.bankAccountName ? "border-danger" : ""}`}>
                                              <i className="fe fe-user"></i>
                                            </div>
                                          </div>
                                          {errors.bankAccountName && errors.bankAccountName.type === "required" && (
                                            <small className="text-danger">Bank Account Name is required.</small>
                                          )}
                                          {errors.bankAccountName && errors.bankAccountName.type !== "required" && (
                                            <small className="text-danger">Bank Account Name not in valid format.</small>
                                          )}
                                        </div>
                                        <div className="col-12">
                                          <label className="form-label" htmlFor="buy-now-bank-account-type">
                                            Bank Account Type <span className="text-danger">*</span>
                                          </label>
                                          <select
                                            name="buy-now-bank-account-type"
                                            className="form-control form-control-lg"
                                            {...(paymentType === 5 && register("bankAccountType", {
                                              required: true
                                            }))}
                                            disabled={isLoading}
                                          >
                                            <option value="business-checking">Business Checking</option>
                                            <option value="checking">Checking</option>
                                            <option value="savings">Savings</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item text-body border-0">
                              <label className="accordion-button accordion-button-radio px-0 border-0 shadow-none" htmlFor="buy-now-payment-bank-wire-method" id="buy-now-payment-method-bank-wire">
                                <span className="col">
                                  <img className="col-1" src="./assets/img/payment/bank-wire.svg" alt="" height="14" />
                                  <span>Bank Transfer</span>
                                </span>
                                <span className="col-auto">
                                  <input
                                    className="form-check-input"
                                    id="buy-now-payment-bank-wire-method"
                                    type="radio"
                                    name="buy-now-payment-method"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#buy-now-payment-bank-wire"
                                    {...register("paymentTerm")}
                                    disabled={isLoading}
                                  />
                                </span>
                              </label>
                              <div id="buy-now-payment-bank-wire" className="accordion-collapse collapse" aria-labelledby="buy-now-payment-method-bank-wire" data-bs-parent="#buy-now-payment-method">
                                <div className="accordion-body p-2 pe-0">
                                  <div className="card card-sm mb-0">
                                    <div className="card-body">Currently not available.</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-block px-3 py-2">
        <div className="row g-0 align-items-center justify-content-center">
          <div className="col">
            <span className="d-block fs-7">Total</span>
            <span className="d-block fw-bold text-primary fs-5">{formatPrice(cartStore.totalPrice)}</span>
          </div>
          <div className="col-auto">
            {buyNowStep === 2 && <button className="btn btn-light me-2" onClick={() => setBuyNowStep(1)}>
              <span className="fe fe-chevron-left"></span>&nbsp;Back
            </button>}
            <button
              className="btn btn-primary px-4"
              type="submit"
              onClick={onBuyNowNextStep}
              disabled={isLoading || (buyNowStep === 1 && !isBillingShippingInfoValid) || (buyNowStep === 2 && !isValid && !watchPaymentTerm)}
            >
              {isLoading && <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;</>}
              {[2].includes(buyNowStep) && <><span className="fe fe-check"></span></>}
              &nbsp;
              <span>
                {buyNowStep === 1 && "Next"}
                {buyNowStep === 2 && "Make a payment"}
              </span>
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}
