import React from "react";
import { useLocation } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useStore } from "../../stores/StoreContext";

export const Sort = () => {
  const { sortStore } = useStore();
  const location = useLocation();

  const onChange = async (e) => {
    const sortBy = e.target.value !== "Sort by" ? e.target.value : null;

    if (location && location.pathname === "/") {
      sortStore.setProductsSortBy(sortBy);
    } else if (location && location.pathname === "/orders") {
      sortStore.setOrdersSortBy(sortBy);
    } else if (location && location.pathname === "/documents") {
      sortStore.setDocumentsSortBy(sortBy);
    }
  };

  return (
    <React.Fragment>
      {location && location.pathname === "/" && (
        <OverlayTrigger overlay={<Tooltip>Sort products</Tooltip>} placement="bottom" rootClose={true}>
          <form>
            <select
              data-choices='{"searchEnabled": false}'
              data-choices-size="sm"
              data-choices-style="flush"
              data-choices-selected-icon="sort-items"
              data-choices-mark-dirty="true"
              data-choices-orientation="right"
              onChange={onChange}
            >
              <option>Sort by</option>

              <option value="Manufacturer ASC">Manufacturer A to Z</option>
              <option value="Manufacturer DESC">Manufacturer Z to A</option>
              <option value="UnitPrice ASC">Round price Low to High</option>
              <option value="UnitPrice DESC">Round price High to Low</option>
              <option value="PackagePrice ASC">Package price Low to High</option>
              <option value="PackagePrice DESC">Package price High to Low</option>
              <option value="Purpose ASC">Purpose</option>
            </select>
          </form>
        </OverlayTrigger>
      )}
      {location && (location.pathname === "/orders" || location.pathname === "/documents") && (
        <OverlayTrigger overlay={<Tooltip>Sort orders</Tooltip>} placement="bottom" rootClose={true}>
          <form>
            <select
              data-choices='{"searchEnabled": false}'
              data-choices-size="sm"
              data-choices-style="flush"
              data-choices-selected-icon="sort-items"
              data-choices-mark-dirty="true"
              data-choices-orientation="right"
              onChange={onChange}
              className={`${location && (location.pathname !== "/orders" || location.pathname !== "/documents") ? "d-none" : ""}`}
              defaultValue={{ label: "Sort by" }}
            >
              <option value="OrderDate ASC">Date Oldest to Newest</option>
              <option value="OrderDate DESC">Date Newest to Oldest</option>
            </select>
          </form>
        </OverlayTrigger>
      )}
    </React.Fragment>
  );
};
