import React from "react";
import { observer } from "mobx-react";
import { ProductRowItem } from "./ProductRowItem";
import { ProductCardItem } from "./ProductCardItem";

export const ProductItem = observer((props) => {
  return (
    <>
      {props.layout === "card" && (
        <ProductCardItem
          key={props.i}
          item={props.item}
          index={props.i}
          subcategory={props.subcategory}
          selectedItem={props.selectedItem}
          searchTerm={props.searchTerm}
          itemCartQuantity={props.itemCartQuantity}
          addToCart={props.addToCart}
          removeFromCart={props.removeFromCart}
          isBudgetReached={props.isBudgetReached}
          isIndividualUser={props.isIndividualUser}
          onSelectedItem={props.onSelectedItem}
        ></ProductCardItem>
      )}
      {props.layout === "row" && (
        <ProductRowItem
          key={props.index}
          item={props.item}
          index={props.index}
          subcategory={props.subcategory}
          selectedItem={props.selectedItem}
          searchTerm={props.searchTerm}
          itemCartQuantity={props.itemCartQuantity}
          addToCart={props.addToCart}
          removeFromCart={props.removeFromCart}
          isBudgetReached={props.isBudgetReached}
          isIndividualUser={props.isIndividualUser}
          onSelectedItem={props.onSelectedItem}
        ></ProductRowItem>
      )}
    </>
  );
});
