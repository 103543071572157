import { makeAutoObservable } from "mobx";

export class SortStore {
  productsSortBy: string;
  ordersSortBy: string;
  documentsSortBy: string;

  constructor() {
    makeAutoObservable(this);
  }

  setProductsSortBy(sortBy) {
    this.productsSortBy = sortBy;
    localStorage.setItem("productsSortBy", this.productsSortBy);
  }

  setOrdersSortBy(sortBy) {
    this.ordersSortBy = sortBy;
    localStorage.setItem("ordersSortBy", this.ordersSortBy);
  }

  setDocumentsSortBy(sortBy) {
    this.documentsSortBy = sortBy;
    localStorage.setItem("documentsSortBy", this.documentsSortBy);
  }
}

export default new SortStore();
