import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import UserService from "../../../services/UserService";
import PasswordInput from "../../../components/PasswordInput";

const SignInPassword = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const onSignInWithOtp = async () => {
    props.onLoginTypeChange("otp");
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(props.onSubmitStep2)}>
        {location.state && location.state.message && (
          <div className={`alert mb-3 alert-${location.state.updatedPassword ? "success" : "danger"}`}>
            <p className="mb-0 fs-7">{location.state.message}</p>
          </div>
        )}
        <div className="my-auto">
          <div className="mb-3">
            <label className="form-label fw-normal" htmlFor="email">
              Sign in with <span className="fw-bold">User ID</span>
            </label>
            <div className="input-group input-group-merge input-group-reverse">
              <input
                className="form-control form-control-lg"
                id="email"
                placeholder="Your email address or phone number"
                aria-label="Your email address or phone number"
                aria-describedby="email-at"
                value={props.email}
                disabled
              />
              <div className="input-group-text" id="email-at">
                <i className="fe fe-at-sign"></i>
              </div>
              <div className="input-group-btn">
                <button className="btn btn-white" onClick={() => props.onLoginTypeChange()} type="button">
                  Change
                </button>
              </div>
            </div>
          </div>
          <div className="mb-2">
            <label className="form-label" htmlFor="password">
              Password
            </label>
            <PasswordInput
              id="password"
              placeholder="Your password"
              inputGroupClassName={errors.password || (props.errors && props.errors.incorrectPassword) ? "" : "mb-3"}
              iconClassName={errors.password || (props.errors && props.errors.incorrectPassword) ? "border-danger text-danger" : ""}
              inputClassName={errors.password || (props.errors && props.errors.incorrectPassword) ? "border-danger" : ""}
              onKeyUp={() => {
                if (props.errors && props.errors.incorrectPassword) {
                  props.errors.incorrectPassword = false;
                }
              }}
              formOptions={register("password", { required: true })}
            />
            {errors.password && <small className="text-danger mb-3">Password is required.</small>}
            {props.errors && props.errors.incorrectPassword && !errors.password && <small className="text-danger mb-3">Incorrect password.</small>}
          </div>
          <div className="row mb-0">
            <div className="col">
              <a href="#!" onClick={onSignInWithOtp}>
                Sign in with OTP
              </a>
            </div>
            <div className="col-auto">
              <a className="text-muted" href="#!" onClick={() => navigate("/forgot-password", { state: { email: props.email } })}>
                Forgot password?
              </a>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="mb-0">
            <button className="btn btn-lg btn-primary w-100" type="submit" disabled={props.isLoading}>
              {props.isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
              &nbsp; Confirm
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default SignInPassword;
